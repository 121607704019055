export function objectToQueryString(obj: object, filterEmptyKeys: boolean = true) {
    let listOfKeys: Array<string>;

    if (filterEmptyKeys) {
        listOfKeys = Object.keys(obj).filter(key => obj[key] !== null && obj[key] !== '');
    } else {
        listOfKeys = Object.keys(obj);
    }

    let query = listOfKeys
        .map(key => `${key}=${encodeURIComponent(obj[key])}`)
        .join('&');
    return query.length > 0 ? `?${query}` : '';
}
