<li class="nav-item level-top">
    <a class="nav-link no-border" [routerLink]="home">
        <div class="inner-link">Home</div>
    </a>
</li>
<li class="nav-item level-top dropdown">
    <a class="nav-link no-border" href="javascript:void(0)" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
        <div class="inner-link">About</div>
    </a>
    <div class="dropdown-menu dropdown-menu-right">
        <a class="dropdown-item" href="https://www.newable.co.uk/about-us/" rel=”noopener”>About us</a>
        <a class="dropdown-item" routerLink="/about/faq">FAQ</a>
        <a class="dropdown-item" routerLink="/about/contact">Contact</a>
        <a class="dropdown-item" href="https://www.newable.co.uk/content-hub/" rel=”noopener”>News and Views</a>
        <a class="dropdown-item" routerLink="/about/case-studies">Case Studies</a>
        <a class="dropdown-item" href="https://www.newable.co.uk/newable-business-loans-policies/" rel=”noopener”>Policies</a>
        <a class="dropdown-item" href="https://commerce.newable.co.uk/" rel=”noopener”>Visit Commerce.newable.co.uk</a>
    </div>
</li>
