import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';

import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';

import { Dictionary } from '@nw-common/common.interfaces';
import { AuthorizationState } from '@nw-auth/authorization.state';


@Directive({
    selector: '[requiredPermission]'
})
export class RequiredPermissionDirective {
    @Select(AuthorizationState.getRole) permissions$: Observable<Dictionary<string>>;

    constructor(
        private templateRef: TemplateRef<any>, private viewContainer: ViewContainerRef, private store: Store
    ) {
    }

    @Input() set requiredPermission(permission: string) {
        this.permissions$.subscribe(permissions => {
            if (permission in permissions) {
                this.viewContainer.createEmbeddedView(this.templateRef);
            } else {
                this.viewContainer.clear();
            }
        });
    }
}
