import { Component, EventEmitter, Inject, Input, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { WindowRef } from '../../services/app.window.service';


@Component({
    selector: 'nw-popup',
    templateUrl: './popup.component.html',
    styleUrls: ['./popup.component.scss']
})
export class PopupComponent {
    confirmButtonName = 'Reset collections';
    cancelButtonName = 'Cancel';

    @Output() confirmReset: EventEmitter<any> = new EventEmitter();

    @Input() modalTitle;
    @Input() modalContent;
    @Input() modalRefresh;
    @Input() modalReset;

    constructor(@Inject(WindowRef) private window: Window, public activeModal: NgbActiveModal) {
    }

    refresh() {
        this.window.location.reload();
        this.activeModal.close();
    }

    confirm() {
        this.confirmReset.emit();
    }

    customise(settings: object) {
        Object.keys(settings).map(key => {
            this[key] = settings[key];
        })
    }
}
