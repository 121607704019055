<footer class="footer">
    <div class="footer__main">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="row">
                        <div class="col-12 col-md-3">
                            <div class="footer__logo">
                                <a href="/">
                                    <img ngSrc="https://www.newable.co.uk/app/uploads/2023/09/newable-logo.svg"
                                         height="40" width="146" priority="true"
                                         alt="Newable logo"
                                    >
                                    <noscript>
                                        <img src="https://www.newable.co.uk/app/uploads/2023/09/newable-logo.svg"
                                             alt="Newable logo">
                                    </noscript>
                                </a>
                            </div>
                            <div class="footer__details">
                                <p class="footer__registered-address">140 Aldersgate Street,
                                    <br>EC1A 4HY
                                    <br>No: 14356119
                                </p>
                            </div>
                        </div>
                        <!-- Wider column -->
                        <div class="col-12 col-md-9">
                            <div class="row">
                                <!-- Footer Column -->
                                <div class="col-6 col-md-4 col-lg">
                                    <p class="footer__link-list-header">About Us</p>
                                    <ul class="footer__link-list">
                                        <!-- Footer link -->
                                        <li>
                                            <a href="https://www.newable.co.uk/" target="">Home</a>
                                        </li>
                                        <!-- Footer link -->
                                        <li>
                                            <a href="https://www.newable.co.uk/about-us/" target="">About Us</a>
                                        </li>
                                        <!-- Footer link -->
                                        <li>
                                            <a href="https://www.newable.co.uk/our-commitment-to-esg/" target="">Commitment
                                                to ESG</a>
                                        </li>
                                        <!-- Footer link -->
                                        <li>
                                            <a href="https://www.newable.co.uk/team/" target="">Our Team</a>
                                        </li>
                                    </ul>
                                </div>
                                <!-- Footer Column -->
                                <div class="col-6 col-md-4 col-lg">
                                    <p class="footer__link-list-header">Resources</p>
                                    <ul class="footer__link-list">
                                        <!-- Footer link -->
                                        <li>
                                            <a href="https://www.newable.co.uk/content-hub/" target="">Content Hub</a>
                                        </li>
                                    </ul>
                                </div>
                                <!-- Footer Column -->
                                <div class="col-6 col-md-4 col-lg">
                                    <p class="footer__link-list-header">Money</p>
                                    <ul class="footer__link-list">
                                        <!-- Footer link -->
                                        <li>
                                            <a href="https://services.newable.co.uk/money/capital/" target="">Newable
                                                Capital</a>
                                        </li>
                                        <!-- Footer link -->
                                        <li>
                                            <a href="https://services.newable.co.uk/money/finance/business-finance/"
                                               target="">Business Finance</a>
                                        </li>
                                        <!-- Footer link -->
                                        <li>
                                            <a href="https://services.newable.co.uk/money/finance/property-finance/"
                                               target="">Property Finance</a>
                                        </li>
                                        <!-- Footer link -->
                                        <li>
                                            <a href="https://services.newable.co.uk/money/export-trade-finance/"
                                               target="">Export Trade Finance</a>
                                        </li>
                                    </ul>
                                </div>
                                <!-- Footer Column -->
                                <div class="col-6 col-md-4 col-lg">
                                    <p class="footer__link-list-header">Advice</p>
                                    <ul class="footer__link-list">
                                        <!-- Footer link -->
                                        <li>
                                            <a href="https://www.newable.co.uk/advice/accelerating-business-growth/"
                                               target="">Accelerating Business Growth</a>
                                        </li>
                                        <!-- Footer link -->
                                        <li>
                                            <a href="https://www.newable.co.uk/advice/what-we-do/" target="">What we
                                                do</a>
                                        </li>
                                        <!-- Footer link -->
                                        <li>
                                            <a href="https://www.newable.co.uk/advice/our-clients/" target="">Our
                                                Clients</a>
                                        </li>
                                        <!-- Footer link -->
                                        <li>
                                            <a href="https://www.newable.co.uk/advice/our-team/" target="">Our Team</a>
                                        </li>
                                    </ul>
                                </div>
                                <!-- Footer Column -->
                                <div class="col-6 col-md-4 col-lg">
                                    <p class="footer__link-list-header">Workspace</p>
                                    <ul class="footer__link-list">
                                        <!-- Footer link -->
                                        <li>
                                            <a href="https://www.newable.co.uk/workspace/" target="_blank">NewFlex</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- Bottom of footer -->
    <div class="footer__bottom">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <hr>
                    <div class="row justify-content-between">
                        <div class="col-12 col-md-3 col-lg-3">
                            <ul class="footer__link-list-horizontal">
                                <li>
                                    © 2024 Newable Partnership Limited
                                </li>
                            </ul>
                        </div>
                        <div class="col-12 col-md-8 col-lg-6">
                            <!-- Legal pages -->
                            <ul class="footer__link-list-horizontal">
                                <li>
                                    <a href="https://www.newable.co.uk/privacy-policy/" target="">Privacy and Cookie
                                        Policy</a>
                                </li>
                                <li>
                                    <a href="https://www.newable.co.uk/modern-slavery-act-statement/" target="">Modern
                                        Slavery Act Statement</a>
                                </li>
                                <li>
                                    <a href="https://www.newable.co.uk/terms-and-conditions/" target="">Terms and
                                        Conditions</a>
                                </li>
                            </ul>
                        </div>
                        <div class="col-12 col-md-3 col-lg-3 order-lg-last order-first">
                            <!-- Social links -->
                            <ul class="footer__link-list-socials">
                                <li>
                                    <a href="https://twitter.com/Newable" target="_blank">
                                        <div class="icon-inner">
                                            <svg xmlns="http://www.w3.org/2000/svg" class="icon-svg"
                                                 viewBox="0 0 512 512">
                                                <path
                                                    d="M496 109.5a201.8 201.8 0 01-56.55 15.3 97.51 97.51 0 0043.33-53.6 197.74 197.74 0 01-62.56 23.5A99.14 99.14 0 00348.31 64c-54.42 0-98.46 43.4-98.46 96.9a93.21 93.21 0 002.54 22.1 280.7 280.7 0 01-203-101.3A95.69 95.69 0 0036 130.4c0 33.6 17.53 63.3 44 80.7A97.5 97.5 0 0135.22 199v1.2c0 47 34 86.1 79 95a100.76 100.76 0 01-25.94 3.4 94.38 94.38 0 01-18.51-1.8c12.51 38.5 48.92 66.5 92.05 67.3A199.59 199.59 0 0139.5 405.6a203 203 0 01-23.5-1.4A278.68 278.68 0 00166.74 448c181.36 0 280.44-147.7 280.44-275.8 0-4.2-.11-8.4-.31-12.5A198.48 198.48 0 00496 109.5z"></path>
                                            </svg>
                                        </div>
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.linkedin.com/company/newable/ " target="_blank">
                                        <div class="icon-inner">
                                            <svg xmlns="http://www.w3.org/2000/svg" class="icon-svg"
                                                 viewBox="0 0 512 512">
                                                <path
                                                    d="M444.17 32H70.28C49.85 32 32 46.7 32 66.89v374.72C32 461.91 49.85 480 70.28 480h373.78c20.54 0 35.94-18.21 35.94-38.39V66.89C480.12 46.7 464.6 32 444.17 32zm-273.3 373.43h-64.18V205.88h64.18zM141 175.54h-.46c-20.54 0-33.84-15.29-33.84-34.43 0-19.49 13.65-34.42 34.65-34.42s33.85 14.82 34.31 34.42c-.01 19.14-13.31 34.43-34.66 34.43zm264.43 229.89h-64.18V296.32c0-26.14-9.34-44-32.56-44-17.74 0-28.24 12-32.91 23.69-1.75 4.2-2.22 9.92-2.22 15.76v113.66h-64.18V205.88h64.18v27.77c9.34-13.3 23.93-32.44 57.88-32.44 42.13 0 74 27.77 74 87.64z"></path>
                                            </svg>
                                        </div>
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.instagram.com/newablegroup/" target="_blank">
                                        <div class="icon-inner">
                                            <svg xmlns="http://www.w3.org/2000/svg" class="icon-svg"
                                                 viewBox="0 0 512 512">
                                                <path
                                                    d="M349.33 69.33a93.62 93.62 0 0193.34 93.34v186.66a93.62 93.62 0 01-93.34 93.34H162.67a93.62 93.62 0 01-93.34-93.34V162.67a93.62 93.62 0 0193.34-93.34h186.66m0-37.33H162.67C90.8 32 32 90.8 32 162.67v186.66C32 421.2 90.8 480 162.67 480h186.66C421.2 480 480 421.2 480 349.33V162.67C480 90.8 421.2 32 349.33 32z"></path>
                                                <path
                                                    d="M377.33 162.67a28 28 0 1128-28 27.94 27.94 0 01-28 28zM256 181.33A74.67 74.67 0 11181.33 256 74.75 74.75 0 01256 181.33m0-37.33a112 112 0 10112 112 112 112 0 00-112-112z"></path>
                                            </svg>
                                        </div>
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.youtube.com/channel/UC3SxmrbXS4gD5VrREvgWVEg" target="_blank">
                                        <div class="icon-inner">
                                            <svg xmlns="http://www.w3.org/2000/svg" class="icon-svg"
                                                 viewBox="0 0 512 512">
                                                <path
                                                    d="M508.64 148.79c0-45-33.1-81.2-74-81.2C379.24 65 322.74 64 265 64h-18c-57.6 0-114.2 1-169.6 3.6C36.6 67.6 3.5 104 3.5 149 1 184.59-.06 220.19 0 255.79q-.15 53.4 3.4 106.9c0 45 33.1 81.5 73.9 81.5 58.2 2.7 117.9 3.9 178.6 3.8q91.2.3 178.6-3.8c40.9 0 74-36.5 74-81.5 2.4-35.7 3.5-71.3 3.4-107q.34-53.4-3.26-106.9zM207 353.89v-196.5l145 98.2z"></path>
                                            </svg>
                                        </div>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</footer>
