import { NgModule } from '@angular/core';
import { ExtraOptions, RouterModule, Routes } from '@angular/router';
import { AppComponent } from '@nw-app/components/app/app.component';
import { RoutingGuard } from '@nw-app/guards/routing.guard';
import { UserGuard } from '@nw-app/guards/authorization.guard';

const routes: Routes = [
  {
    path: 'admin',
    loadChildren: () => import('./modules/admin/admin.module').then(m => m.AdminModule)
  },
  {
    path: '',
    component: AppComponent,
    children: [
      {
        path: '',
        redirectTo: 'introducers',
        pathMatch: 'full'
      },
      {
        path: 'about',
        loadChildren: () => import('./modules/about/about.module').then(m => m.AboutModule)
      },
      {
        path: 'introducers',
        loadChildren: () => import('./modules/introducers/introducers.module').then(m => m.IntroducersModule)
      },
      {
        path: 'login',
        loadChildren: () => import('./modules/authorization/authorization.module').then(m => m.AuthorizationModule)
      },
      {
        path: 'logout',
        redirectTo: '/login'
      },
      {
        path: 'password',
        loadChildren: () => import('./modules/password/password.module').then(m => m.PasswordModule)
      },
      {
        path: 'confirm_email/:token',
        loadChildren: () => import('./modules/confirm-email/confirm-email.module').then(m => m.ConfirmEmailModule)
      },
      {
        path: 'unsubscribe/:token',
        loadChildren: () => import('./modules/unsubscribe/unsubscribe.module').then(m => m.UnsubscribeModule)
      },
      {
        path: 'loans',
        canActivate: [UserGuard],
        data: {
          userTypes: ['introducer', 'introducer_coworker']
        },
        loadChildren: () => import('./modules/loans/loans.module').then(m => m.LoansModule),
        title: 'Loans - Newable'
      },
      {
        path: 'coworkers',
        canActivate: [UserGuard],
        data: {
          userTypes: ['introducer', 'introducer_coworker']
        },
        loadChildren: () => import('./modules/coworkers/coworkers.module').then(m => m.CoworkersModule),
        title: 'Coworkers - Newable'
      },
      {
        path: 'gocardless',
        loadChildren: () => import('./modules/gocardless/gocardless.module').then(m => m.GocardlessModule)
      },
      {
        path: 'codat/:loan_id',
        loadChildren: () => import('./modules/codat/codat.module').then(m => m.CodatModule)
      },
      {
        path: 'register',
        loadChildren: () => import('./modules/registration/registration.module').then(m => m.RegistrationModule)
      },
      {
        path: 'register/borrower',
        loadChildren: () => import('./modules/registration/modules/borrower/borrower-registration.module').then(m => m.BorrowerRegistrationModule)
      },
      {
        path: 'register/broker',
        loadChildren: () => import('./modules/registration/modules/broker/broker-registration.module').then(m => m.BrokerRegistrationModule)
      },
      {
        path: 'loan_application',
        canActivate: [UserGuard],
        data: {
          userTypes: ['introducer', 'introducer_coworker']
        },
        loadChildren: () => import('./modules/loan-application/loan-application.module').then(m => m.LoanApplicationModule)
      },
      {
        path: 'error',
        loadChildren: () => import('./modules/error-page/error-page.module').then(m => m.ErrorPageModule)
      },
      {
        path: 'confirm-agreement/:token',
        loadChildren: () => import('./modules/new-broker-agreement/new-broker-agreement.module').then(m => m.NewBrokerAgreementModule)
      },
      {
        path: 'confirm_aip_email/:token',
        loadChildren: () => import('./modules/confirm-aip-email/confirm-aip-email.module').then(m => m.ConfirmAipEmailModule)
      },
      {
        path: '**',
        canActivate: [RoutingGuard],
        loadChildren: () => import('./modules/not-found/not-found.module').then(m => m.NotFoundModule)
      },
    ]
  },
  {
    path: '**',
    component: AppComponent,
    loadChildren: () => import('./modules/not-found/not-found.module').then(m => m.NotFoundModule)
  },
];

const config: ExtraOptions = {
  enableTracing: false,
  anchorScrolling: 'enabled',
  scrollPositionRestoration: 'enabled',
  scrollOffset: [0, 90]
}

@NgModule({
  imports: [RouterModule.forRoot(routes, config)],
  exports: [RouterModule],
  providers: [
    RoutingGuard,
    UserGuard
  ]
})
export class AppRoutingModule {
}
