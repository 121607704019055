import { Injectable } from '@angular/core';

import { DEFAULT_INTERRUPTSOURCES, Idle } from '@ng-idle/core';
import { environment } from '@nw-root/environments/environment';


interface Refresher {
    callback: Function;
    refreshInterval: number;
    suspendRefreshInterval: number;
    suspendTimeout: number;

    suspendedRefreshing: boolean;

    intervalObj?: any;
    suspendedIntervalObj?: any;
    timeoutObj?: any;
}


@Injectable()
export class RefreshService {
    private refreshingObjects: Refresher[];

    constructor(private idle: Idle) {
        this.refreshingObjects = [];

        this.idle.setIdle(5);
        this.idle.setTimeout(9999999999999);  // check if we can turn off timeout
        this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);
        this.idle.onIdleEnd.subscribe(() => { this.onIdleEnd(); });
        this.idle.onIdleStart.subscribe(() => { this.onIdleStart(); });
        if (environment.production) {
            this.idle.watch();
        }
    }

    /**
     *
     * @param callback: Refresh function to be called, pass it as lambda
     * @param refreshInterval: How often refresh function should be called
     * @param suspendRefreshInterval: How often refresh function should be called during suspend
     * @param suspendTimeout: Time starting from Idle start, after we should mark as suspended
     */
    register(
        callback, refreshInterval=30, suspendRefreshInterval=120, suspendTimeout=30,
    ) {
        const suspendedRefreshing = false;
        const refresher: Refresher = {
            callback, refreshInterval, suspendRefreshInterval, suspendTimeout,
            suspendedRefreshing
        };

        this.refreshingObjects.push(refresher);
        this.setUpRefreshing(refresher);

        return this.unregister(refresher);
    }

    /**
     * Unregister specific refresher
     *
     * @param {Refresher} refresher: object to remove
     * @returns {() => void} function which should be called in order to remover refresher
     */
    unregister(refresher: Refresher) {
        return () => {
            clearInterval(refresher.intervalObj);
            clearTimeout(refresher.timeoutObj);
            clearInterval(refresher.suspendedIntervalObj);

            const index = this.refreshingObjects.indexOf(refresher);
            if (index > -1) {
                this.refreshingObjects.splice(index, 1);
            }
        }
    }

    /**
     * Unregister all registered refresher
     */
    unregisterAllCallbacks(){
        for (const refObj of this.refreshingObjects) {
            this.unregister(refObj)();
        }
    }

    protected setUpRefreshing(refresher: Refresher) {
        if (!environment.e2e) {
            refresher.intervalObj = setInterval(
                () => {
                    if (!refresher.suspendedRefreshing) {
                        refresher.callback();
                    }
                },
                refresher.refreshInterval * 1000
            );
        }
        refresher.suspendedRefreshing = false;
    }

    protected onIdleStart() {
        for (const refresher of this.refreshingObjects) {
            refresher.timeoutObj = setTimeout(() => {
                refresher.suspendedRefreshing = true;
            }, refresher.suspendTimeout * 1000);

            refresher.suspendedIntervalObj = setInterval(
                () => {
                    refresher.callback();
                },
                refresher.suspendRefreshInterval * 1000
            );
        }
    }

    protected onIdleEnd() {
        for (const refresher of this.refreshingObjects) {
            clearTimeout(refresher.timeoutObj);
            refresher.suspendedRefreshing = false;
            clearInterval(refresher.suspendedIntervalObj);
        }
    }
}
