<div class="modal-header">
    <h4 class="modal-title">{{ modalTitle }}</h4>
    <button type="button" class="close" aria-label="Close"
            *ngIf="!modalRefresh"
            (click)="activeModal.dismiss()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <p *ngFor="let row of modalContent">{{ row }}</p>
</div>

<div *ngIf="modalReset" class="modal-footer">
        <div class=text-left>
        <button id="refresh-button" type="button" class="btn btn-azure box-button"
                (click)="confirm()">
            {{ confirmButtonName }}
        </button>
    </div>
    <div class="text-right">
        <button id="continue-button" type="button" class="btn btn-azure box-button"
                (click)="activeModal.close()">
            {{ cancelButtonName }}
        </button>
    </div>

</div>

<div *ngIf="!modalRefresh && !modalReset" class="modal-footer">
    <button id="close-button" type="button" class="btn btn-azure box-button"
            (click)="activeModal.close()">
        Close
    </button>
</div>

<div *ngIf="modalRefresh" class="modal-footer">
    <div class="text-left">
        <button id="continue-button" type="button" class="btn btn-azure box-button"
                (click)="activeModal.close()">
            Continue work
        </button>
    </div>
    <div class=text-right>
        <button id="refresh-button" type="button" class="btn btn-azure box-button"
                (click)="refresh()">
            Refresh
        </button>
    </div>
</div>
