import { Component, DoCheck, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { OverlayRef } from '@angular/cdk/overlay';

import { OverlayService } from '../../services/overlay.service';
import { ProgressSpinnerMode } from '@angular/material/progress-spinner';

@Component({
    selector: 'nw-progress-spinner',
    templateUrl: './progress-spinner.component.html',
    styleUrls: ['./progress-spinner.component.scss']
})
export class ProgressSpinnerComponent implements OnInit, DoCheck {
    @Input() displaySpinner: boolean;
    @Output() overlayClosed = new EventEmitter();
    @ViewChild('spinnerRef') private spinnerRef: TemplateRef<any>;

    public mode: ProgressSpinnerMode = 'indeterminate';
    public diameter = 60;
    public value = 10;
    private overlayRef: OverlayRef;

    constructor(private viewContainerRef: ViewContainerRef, private overlayService: OverlayService) {
    }

    ngOnInit() {
        this.overlayRef = this.overlayService.createOverlay({
            hasBackdrop: true,
            positionStrategy: this.overlayService.positionGloballyCenter(),
        });
    }

    ngDoCheck() {
        if (this.displaySpinner && !this.overlayRef.hasAttached() && this.spinnerRef) {
            this.overlayService.attachTemplatePortal(this.overlayRef, this.spinnerRef, this.viewContainerRef);
        } else if (!this.displaySpinner && this.overlayRef.hasAttached()) {
            this.overlayRef.detach();
            this.overlayClosed.emit();
        }
    }
}
