export class CookiesBanner {
    static readonly type = 'COOKIES_BANNER';

    constructor(public payload: boolean) {}
}

export class MainPath {
    static readonly type = 'MAIN_PATH';

    constructor(public payload: string) {}
}
