<div>
    <div class="input-group">
        <div class="input-group-prepend">
            <span class="input-group-text">£</span>
        </div>
        <input type="number" [min]="min" [max]="max" [step]="step"
               [(ngModel)]="value" (ngModelChange)="onChange($event)"
               [ngModelOptions]="{updateOn: 'blur'}" class="form-control">
    </div>
</div>
