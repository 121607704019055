import { Component, OnInit } from '@angular/core';
import { BrokerRegistrationService } from '@nw-registration/modules/broker/broker-registration.service';

@Component({
    selector: 'nw-load-broker-agreement',
    templateUrl: './load-broker-agreement.component.html',
    styleUrls: ['./load-broker-agreement.component.scss']
})
export class LoadBrokerAgreementComponent implements OnInit {
    agreementUrl: string;

    constructor(private brokerService: BrokerRegistrationService) {
    }

    ngOnInit() {
        this.loadIntroducerAgreement();
    }

    loadIntroducerAgreement() {
        const touchDevice = (navigator.maxTouchPoints || 'ontouchstart' in document.documentElement);
        this.brokerService.loadIntroducerAgreement().subscribe(data => {
            const encodedData = encodeURIComponent(data);
            let dirtyUrl = data;
            if (touchDevice) {
                dirtyUrl = `https://drive.google.com/viewerng/viewer?url=${ encodedData }&pid=explorer&efh=false&a=v&chrome=false&embedded=true`;
            }
            this.agreementUrl = dirtyUrl;
        });
    }

}
