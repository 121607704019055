import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';

import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';

import { UserFailLogin } from '@nw-auth/authorization.actions';
import { AuthorizationState } from '@nw-auth/authorization.state';
import { TokenService } from '@nw-app/modules/authorization/services/token.service';
import { AuthorizationStateModel } from '@nw-auth/authorization.interfaces';
import { take } from 'rxjs/operators';

@Injectable()
export class UserGuard implements CanActivate {
    @Select(AuthorizationState.getUser) user$: Observable<AuthorizationStateModel>;

    constructor(
        private router: Router, private store: Store, private tokenService: TokenService
    ) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const requiredUserTypes: string[] = route.data['userTypes'] || [];
        const token = this.tokenService.getTokenOrLogout();

        let userType: string;
        let applicationFinished: boolean;

        if (token) {
            this.user$.pipe(take(1)).subscribe(user => {
                applicationFinished = user.broker_application_finished;
                userType = user.role;
            });
            if (userType === 'introducer' && !applicationFinished) {
                this.router.navigate([`/register/broker`]);
                return false;
            }
            if (requiredUserTypes.indexOf(userType) > -1) {
                return true;
            }
        }
        this.store.dispatch(new UserFailLogin('Please log in'));

        let redirectUrl = 'login';
        if (requiredUserTypes.indexOf('admin') > -1) {
            redirectUrl = `admin/${ redirectUrl }`;
            this.router.navigate([redirectUrl]);
            return false;
        }
        this.router.navigate([`${ redirectUrl }`]);

        return false;
    }
}
