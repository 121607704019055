import {
    BusinessPlanFormData,
    CompanyDetailsFormData,
    CurrentBorrowingFormData, DebtorFormData,
    DocumentsStepFormData,
    LoanApplicationDetailsFormData,
    NewLoanApplicationEntity,
    ShareholderSearchData,
    ShareholdersFormData
} from '@nw-app/modules/loan-application/loan-application.interfaces';
import { FileItem } from 'ng2-file-upload';

export class SetCurrentApplicationId {
    static readonly type = 'SET_CURRENT_APPLICATION_ID';

    constructor(public id: number | string, public fromEligibilityChecker: boolean = false) {
    }
}

export class SetFromEligibilityChecker {
    static readonly type = 'SET_FROM_ELIGIBILITY_CHECKER';

    constructor(public value: boolean) {
    }
}

export class GetLoanApplication {
    static readonly type = 'GET_LOAN_APPLICATION';

    constructor(public id: number, public form: string | null = null) {
    }
}

export class GetLoanApplicationAttachments {
    static readonly type = 'GET_LOAN_APPLICATION_ATTACHMENTS';

    constructor() {
    }
}

export class UpdateLoanApplication {
    static readonly type = 'GET_LOAN_APPLICATION_SUCCESS';

    constructor(public payload: NewLoanApplicationEntity, public form: string | null = null ) {
    }
}

export class LoanApplicationError {
    static readonly type = 'LOAN_APPLICATION_ERROR';

    constructor(public error: any, public step: string) {
    }
}

export class CreateLoanApplication {
    static readonly type = 'CREATE_LOAN_APPLICATION';

    constructor(public payload: LoanApplicationDetailsFormData) {
    }
}

export class CreateLoanApplicationSuccess {
    static readonly type = 'CREATE_LOAN_APPLICATION_SUCCESS';

    constructor(public loan: NewLoanApplicationEntity) {
    }
}

export class SaveLoanCompanyDetails {
    static readonly type = 'SAVE_LOAN_COMPANY_DETAILS';

    constructor(public payload: CompanyDetailsFormData) {
    }
}

export class SaveLoanCompanyDetailsSuccess {
    static readonly type = 'SAVE_LOAN_COMPANY_DETAILS_SUCCESS';

    constructor(public loan: NewLoanApplicationEntity) {
    }
}

export class SaveShareholdersDetails {
    static readonly type = 'SAVE_SHAREHOLDERS_DETAILS';

    constructor(public payload: ShareholdersFormData) {
    }
}

export class SaveShareholdersDetailsSuccess {
    static readonly type = 'SAVE_SHAREHOLDERS_DETAILS_SUCCESS';

    constructor(public loan: NewLoanApplicationEntity) {
    }
}

export class SaveBusinessPlan {
    static readonly type = 'SAVE_BUSINESS_PLAN';

    constructor(public payload: BusinessPlanFormData) {
    }
}

export class SaveBusinessPlanSuccess {
    static readonly type = 'SAVE_BUSINESS_PLAN_SUCCESS';

    constructor(public loan: NewLoanApplicationEntity) {
    }
}

export class ShareholderSearch {
    static readonly type = 'SHAREHOLDER_SEARCH';

    constructor(public payload: ShareholderSearchData[], public loanId: number) {
    }
}

export class SaveDocumentsStep {
    static readonly type = 'SAVE_REFERRAL_CODE';

    constructor(public payload: DocumentsStepFormData, public finish: boolean) {
    }
}

export class FinishApplication {
    static readonly type = 'FINISH_APPLICATION';

    constructor() {
    }
}

export class SaveDocumentsSuccess {
    static readonly type = 'SAVE_DOCUMENTS_SUCCESS';

    constructor(public loanId: number, public redirectToSubmit: boolean = false) {
    }
}

export class SaveCurrentBorrowing {
    static readonly type = 'SAVE_CURRENT_BORROWING';

    constructor(public payload: CurrentBorrowingFormData) {
    }
}

export class SaveCurrentBorrowingSuccess {
    static readonly type = 'SAVE_CURRENT_BORROWING_SUCCESS';

    constructor(public loan: NewLoanApplicationEntity) {
    }
}

export class SaveDebtor {
    static readonly type = 'SAVE_DEBTOR';

    constructor(public payload: DebtorFormData, public isSupplier: boolean = false) {
    }
}

export class SaveDebtorSuccess {
    static readonly type = 'SAVE_DEBTOR_SUCCESS';

    constructor(public loan: NewLoanApplicationEntity) {
    }
}


export class AddFiles {
    static readonly type = 'ADD_FILES';

    constructor(public files: FileItem[], public groupName: string) {
    }
}

export class ClearForms {
    static readonly type = 'CLEAR_FORMS';

    constructor() {
    }
}

export class ClearFiles {
    static readonly type = 'CLEAR_FILES';

    constructor() {
    }
}

export class SetSpinner {
    static readonly type = 'SET_SPINNER';

    constructor(public spinner: boolean) {
    }
}

export class SetApplicationType {
    static readonly type = 'SET_APPLICATION_TYPE';

    constructor(public payload: string) {
    }
}
