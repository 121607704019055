import { Pipe, PipeTransform } from '@angular/core';

const ordinals: string[] = ['th', 'st', 'nd', 'rd'];

@Pipe({
    name: 'nwOrdinal'
})
export class NwOrdinalPipe implements PipeTransform {

    transform(number: any): string {
        const v = number % 100;
        return number + (ordinals[(v - 20) % 10] || ordinals[v] || ordinals[0]);
    }

}
