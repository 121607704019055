import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { EligibilityCheckData, EligibilityCheckResponse } from './eligibility.interfaces';
import { environment } from '@nw-root/environments/environment';

@Injectable()
export class EligibilityService {
    constructor(private http: HttpClient) {
    }

    checkEligibility(data: EligibilityCheckData) {
        return this.http.post<EligibilityCheckResponse>(`${environment.apiBaseURL}/api/v1/eligibility_check`, data);
    }
}
