import { Component, Input } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

@Component({
    selector: 'nw-menu-logged-in',
    templateUrl: './menu-logged-in.component.html',
    styleUrls: ['../styles/common.menu.scss']
})
export class MenuLoggedInComponent {

    constructor() {}
}
