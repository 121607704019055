<div class="amount-slider">
    <div class="container">
        <div class="row">
            <div class="col-md-12 col-lg-6 col-side">
                <div class="left-side">
                    <span class="slider-title">How much would you like to borrow?</span>

                    <div class="slider-row">
                        <span
                            class="slider-border-value">{{ min | nwCurrency }}</span>
                        <mat-slider class="slider" [min]="min" [max]="max" [step]="step"
                                    (input)="sliderChange($event)" [(ngModel)]="amount"
                                    [value]="amount"></mat-slider>
                        <span
                            class="slider-border-value">{{ max | nwCurrency }}</span>
                    </div>

                    <div class="input-mobile d-block d-sm-none">
                        <div class="label">
                            Loan amount ({{ min | nwCurrency }} - {{ max | nwCurrency }})
                        </div>

                        <nw-amount-input [min]="min" [max]="max" [step]="step" [(value)]="amount"></nw-amount-input>
                    </div>

                    <div class="label">Term</div>
                    <div class="btn-group-toggle years-selector" ngbRadioGroup name="months"
                         [(ngModel)]="period" ngDefaultControl
                         (ngModelChange)="monthsChange($event)">
                        <label *ngFor="let item of monthsSelectorData" ngbButtonLabel
                               class="years-item">
                            <input ngbButton type="radio" [value]="item.value">{{ item.name }}
                        </label>

                    </div>

                    <div class="years-selector-mobile">
                        <select [(ngModel)]="period" name="months"
                                (ngModelChange)="monthsChange($event)">
                            <option *ngFor="let item of monthsSelectorData"
                                    value="{{ item.value }}">{{ item.name }}</option>
                        </select>
                    </div>

                    <div class="annotation">
                        <span>
                            For illustrative purposes only. Example shown based on 16% interest rate per
                            year and 4% arrangement fee deducted at disbursement. All loans are subject to status.
                            Loans subject to satisfactory credit and affordability checks.
                        </span>
                    </div>
                </div>

            </div>
            <div class="col-md-12 col-lg-6 col-side right">
                <div class="right-side">
                    <table class="loan-amount">
                        <tr>
                            <td><span class="loan-amount-title">Loan amount</span></td>
                            <td class="align-right"><span
                                class="loan-value">{{ amount | nwCurrency }} </span>
                            </td>
                        </tr>

                        <tr>
                            <td>
                                <span class="sub-loan-amount-title">Arrangement fee</span>
                            </td>
                            <td class="align-right"><span
                                class="sub-loan-value">{{ arrangementFee | nwCurrency }} </span>
                            </td>
                        </tr>

                        <tr>
                            <td>
                                <span class="sub-loan-amount-title">Monthly repayments</span>
                            </td>
                            <td class="align-right"><span
                                class="sub-loan-value">{{ monthlyRepayments | nwCurrency }} </span>
                            </td>
                        </tr>

                        <tr>
                            <td><span class="sub-loan-amount-title">Total loan repayment</span></td>
                            <td class="align-right"><span
                                class="sub-loan-value">{{ totalLoanRepayment | nwCurrency }} </span>
                            </td>
                        </tr>
                    </table>

                    <a routerLink="{{ (user$ | async).isLoggedIn ? '/loan_application/NEW' : '/register' }}"
                       class="btn btn-azure apply-button">APPLY NOW</a>
                </div>
            </div>
        </div>
    </div>
</div>
