import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSliderModule } from '@angular/material/slider';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { AmountInputComponent } from './components/amount-input/amount-input.component';
import { AmountSliderComponent } from './components/amount-slider/amount-slider.component';
import { CookieLawBannerComponent } from './components/cookie-law-banner/cookie-law-banner.component';
import { ContactFooterComponent } from './components/contact-footer/contact-footer.component';
import { HeaderBannerComponent } from './components/header-banner/header-banner.component';
import { PopupComponent } from './components/popup/popup.component';

import { DebugPipe } from './pipes/debug.pipe';
import { NwCurrencyPipe } from './pipes/currency.pipe';
import { NwPercentPipe } from './pipes/percent.pipe';
import { NwPeriodPipe } from './pipes/period.pipe';
import { SafePipe } from './pipes/safe.pipe';

import { EnabledDirective } from '@nw-common/directives/enabled.directive';
import { CurrencyInputDirective } from '@nw-common/directives/currency-input.directive';

import { ScriptsService } from './services/scripts.service';
import { CustomPopupService } from './services/popup.service';
import { EnabledsService } from '@nw-common/services/enableds.service';
import { RequiredPermissionDirective } from './directives/required-permission';
import { AmountInputDirective } from './directives/amount-input.directive';
import { CompanyFinderService } from '@nw-common/services/company-finder.service';
import { NwOrdinalPipe } from './pipes/ordinal.pipe';

import { OverlayService } from '@nw-common/services/overlay.service';
import { ProgressSpinnerComponent } from '@nw-common/components/progress-spinner/progress-spinner.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { OverlayModule } from '@angular/cdk/overlay';
import { LoadBrokerAgreementComponent } from '@nw-common/components/load-broker-agreement/load-broker-agreement.component';
import { CovidComponent } from './components/covid/covid.component';
import { NgxsModule } from '@ngxs/store';
import { CommonsState } from '@nw-common/commons.state';

@NgModule({
    imports: [
        NgbModule,
        FormsModule,
        ReactiveFormsModule,
        MatSliderModule,
        CommonModule,
        MatProgressSpinnerModule,
        OverlayModule,
        NgxsModule.forFeature([
            CommonsState,
        ]),
        RouterModule,
    ],
    declarations: [
        AmountInputComponent,
        AmountSliderComponent,
        CookieLawBannerComponent,
        AmountSliderComponent,
        HeaderBannerComponent,
        CurrencyInputDirective,
        EnabledDirective,
        NwPeriodPipe,
        NwCurrencyPipe,
        NwPercentPipe,
        PopupComponent,
        DebugPipe,
        SafePipe,
        RequiredPermissionDirective,
        ContactFooterComponent,
        AmountInputDirective,
        NwOrdinalPipe,
        ProgressSpinnerComponent,
        LoadBrokerAgreementComponent,
        CovidComponent
    ],
    providers: [
        EnabledsService,
        ScriptsService,
        CustomPopupService,
        CompanyFinderService,
        OverlayService,
    ],
    exports: [
        AmountInputComponent,
        AmountSliderComponent,
        CookieLawBannerComponent,
        AmountSliderComponent,
        HeaderBannerComponent,
        CurrencyInputDirective,
        EnabledDirective,
        NwPeriodPipe,
        NwCurrencyPipe,
        NwPercentPipe,
        DebugPipe,
        SafePipe,
        RequiredPermissionDirective,
        ContactFooterComponent,
        AmountInputDirective,
        NwOrdinalPipe,
        ProgressSpinnerComponent,
        LoadBrokerAgreementComponent,
        CovidComponent
    ]
})
export class NwCommonModule {
}
