<div class="contact">
    <div class="container text-center">
        <img src="assets/icons/chat.svg">
        <h2>Get in touch with our team</h2>
        <span *ngIf="!nf" class="contact-details d-none d-md-inline">t: +44 (0)333 005 0459 | e: lending@newable.co.uk</span>
        <span *ngIf="!nf"class="contact-details d-md-none">t: +44 (0)333 005 0459<br>e: lending@newable.co.uk</span>
        <span *ngIf="nf" class="contact-details d-none d-md-inline">t: +44 (0)333 005 0459 | e: info@newable.co.uk</span>
        <span *ngIf="nf"class="contact-details d-md-none">t: +44 (0)333 005 0459<br>e: info@newable.co.uk</span>
    </div>
</div>
