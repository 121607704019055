import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import shared from '@nw-root/../../shared.json';
import { CommonsState } from '@nw-common/commons.state';
import { Select } from '@ngxs/store';

@Component({
    selector: 'nw-footer',
    templateUrl: './footer.component.html',
    styleUrls: [ './footer.component.scss' ]
})
export class FooterComponent implements OnInit {
    @Select(CommonsState.cookieBannerVisible) cookiesBannerVisible: Observable<boolean>;
    shared: any;

    constructor() {
        this.shared = shared;
    }

    ngOnInit() {
    }
}
