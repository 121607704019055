/**
 * Get error object (bad request|throw's object|other objects)
 * and return the message sent with response or throw error in string format
 */
export function getErrorMessage(errorObj: any, getParticularKey: string = 'detail'): string | any {
    if (typeof errorObj === 'object') {
        if (errorObj.name === 'HttpErrorResponse' && errorObj.error) {
            if (getParticularKey && errorObj.error[getParticularKey]) {
                return errorObj.error[getParticularKey];
            }

            let response = '';
            Object.keys(errorObj.error).forEach(key => {
                response += `'${key.toString()}': "${errorObj.error[key].toString()}"\n`;
            });
            return response;
        }
        return 'Problem with saving data';
    }
    return errorObj;
}
