import { BrokerRegistrationEntity } from '@nw-registration/modules/broker/broker-registration.interfaces';
import { ShareholderSearchData } from '@nw-app/modules/loan-application/loan-application.interfaces';

export class GetBrokerApplication {
    static readonly type = 'GET_BROKER_APPLICATION';

}

export class GetBrokerApplicationSuccess {
    static readonly type = 'GET_BROKER_APPLICATION_SUCCESS';

    constructor(public payload: BrokerRegistrationEntity) {
    }
}

export class GetBrokerApplicationError {
    static readonly type = 'GET_BROKER_APPLICATION_ERROR';

    constructor(public error: string) {
    }
}

export class BrokerRegister {
    static readonly type = 'BROKER_REGISTER';

    constructor(public payload: any) {
    }
}

export class BrokerRegisterSuccess {
    static readonly type = 'BROKER_REGISTER_SUCCESS';

    constructor(public register_token: any) {
    }
}

export class BrokerRegisterError {
    static readonly type = 'BROKER_REGISTER_ERROR';

    constructor(public error: any) {
    }
}

export class GetHeardFrom {
    static readonly type = 'GET_HEARD_FROM';
}

export class BrokerCompanyRegister {
    static readonly type = 'BROKER_COMPANY_REGISTER';

    constructor(public payload: any) {
    }
}

export class BrokerCompanyRegisterSuccess {
    static readonly type = 'BROKER_COMPANY_REGISTER_SUCCESS';
}

export class BrokerCompanyRegisterError {
    static readonly type = 'BROKER_COMPANY_REGISTER_ERROR';

    constructor(public error: string) {
    }
}

export class BrokerRegisterOwners {
    static readonly type = 'BROKER_REGISTER_OWNERS';

    constructor(public payload: any) {
    }
}

export class BrokerRegisterOwnersSuccess {
    static readonly type = 'BROKER_REGISTER_OWNERS_SUCCESS';
}

export class BrokerRegisterOwnersError {
    static readonly type = 'BROKER_REGISTER_OWNERS_ERROR';

    constructor(public error: string) {
    }
}

export class BrokerRegisterAgreement {
    static readonly type = 'BROKER_REGISTER_AGREEMENT';
}

export class BrokerRegisterAgreementSuccess {
    static readonly type = 'BROKER_REGISTER_AGREEMENT_SUCCESS';
}

export class BrokerRegisterAgreementError {
    static readonly type= 'BROKER_REGISTER_AGREEMENT_ERROR';

    constructor(public error: string) {
    }
}

export class BrokerSubmitApply {
    static readonly type = 'BROKER_SUBMIT_APPLY';
}

export class BrokerSubmitApplySuccess {
    static readonly type = 'BROKER_SUBMIT_APPLY_SUCCESS';
}

export class BrokerSubmitApplyError {
    static readonly type = 'BROKER_SUBMIT_APPLY_ERROR';

    constructor(public error: string) {
    }
}

export class DirectorsSearch {
    static readonly type = 'DIRECTORS_SEARCH';

    constructor(public payload: ShareholderSearchData[]) {
    }
}

export class CompaniesHousePartialDetails {
    static readonly type = 'COMPANIES_HOUSE_PARTIAL';

    constructor(public address: any, public registrationNumber: string, public businessName: string) {
    }
}

export class GetCompaniesHouseDetails {
    static readonly type = 'GET_COMPANIES_HOUSE_DETAILS';

    constructor(public registration_number: string, public validate = false) {
    }
}

export class CompaniesHouseDetailsSuccess {
    static readonly type = 'COMPANIES_HOUSE_DETAILS_SUCCESS';

    constructor(public payload: any) {
    }
}

export class CompaniesHouseDetailsError {
    static readonly type = 'COMPANIES_HOUSE_DETAILS_ERROR';
}

export class ValidateNumberError {
    static readonly type = 'VALIDATE_NUMBER_ERROR';

    constructor(public match = true) {
    }

}
