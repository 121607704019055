import { Action, Selector, State, StateContext } from '@ngxs/store';
import { EligibilityCheckEntity } from './eligibility.interfaces';
import {
    GetElibigilityDataFromLocalStorage,
    GetEligbilityCheckError,
    GetEligibilityCheck,
    GetEligibilityCheckSuccess,
    ResetEligibility
} from './eligibility.actions';
import { EligibilityService } from '@nw-loans/components/eligibility/eligibility.service';
import { Injectable } from '@angular/core';

export const defaultEligibilityState = {
    data: null,
    error: null,
};

export interface EligibilityStateModel {
    data: EligibilityCheckEntity;
    error: string;
}

@State<EligibilityStateModel>({
    name: 'eligibility',
    defaults: { ...defaultEligibilityState }
})
@Injectable()
export class EligibilityState {
    constructor(private eligibilityService: EligibilityService) {
    }

    @Selector()
    static eligibilityData(state: EligibilityStateModel) {
        return state.data;
    }

    @Selector()
    static eligibilityError(state: EligibilityStateModel) {
        return state.error;
    }

    @Action(GetElibigilityDataFromLocalStorage)
    getElibigilityFromLocalStorage(ctx: StateContext<EligibilityStateModel>) {
        ctx.patchState({
            ...JSON.parse(window.localStorage.eligibility)
        });
    }

    @Action(GetEligibilityCheck)
    getEligbilityCheck(ctx: StateContext<EligibilityStateModel>, action: GetEligibilityCheck) {
        return this.eligibilityService.checkEligibility(action.payload).subscribe(
            response => {
                ctx.dispatch(new GetEligibilityCheckSuccess(response));
            },
            error => {
                const message = (
                    error.status === 404 ? 'Company with given registration number was not found' : 'Something went wrong...'
                );
                ctx.dispatch(new GetEligbilityCheckError(message));
            }
        );
    }

    @Action(GetEligibilityCheckSuccess)
    getEligibilityCheckSuccess(ctx: StateContext<EligibilityStateModel>, action: GetEligibilityCheckSuccess) {
        ctx.patchState({
            data: {
                company: {
                    name: action.payload.company.name,
                    registration_number: action.payload.company.registration_number,
                    address: action.payload.company.address
                },
                request_data: action.payload.request_data,
                status: action.payload.status
            },
            error: null
        });
    }

    @Action(GetEligbilityCheckError)
    getEligibilityCheckError(ctx: StateContext<EligibilityStateModel>, action: GetEligbilityCheckError) {
        ctx.patchState({
            data: null,
            error: action.error,
        });
    }

    @Action(ResetEligibility)
    resetEligibility(ctx: StateContext<EligibilityStateModel>) {
        ctx.patchState({
            data: null,
            error: null
        });
    }
}
