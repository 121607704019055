import { AuthorisationResponsePayload, ElibilityCommonData, LoginFormData, UserDetails } from '@nw-auth/authorization.interfaces';

export class UserLogin {
    static readonly type = 'LOGIN';

    constructor(public loginForm: LoginFormData, public ncf = false) {
    }
}

export class UserSuccessLogin {
    static readonly type = 'LOGIN_SUCCESS';

    constructor(public payload: AuthorisationResponsePayload, public isAdmin = false, public ncf = false) {
    }
}

export class UserFailLogin {
    static readonly type = 'LOGIN_FAIL';

    constructor(public payload: string) {
    }
}

export class UserLogout {
    static readonly type = 'LOGOUT';

    constructor(public redirect = true) {
    }
}

export class UserLogoutOnOtherTab {
    static readonly type = 'LOGOUT_ON_OTHER_TAB';
}

export class UserNotConfirmed {
    static readonly type = 'USER_NOT_CONFIRMED';

    constructor(public payload: string) {
    }
}

export class GetEligibilityCheckData {
    static readonly type = 'ELIGIBILITY_CHECK_DATA';

    constructor(public payload: ElibilityCommonData) {
    }
}

export class GetUserDetails {
    static readonly type = 'GET_USER_DATA';

    constructor() {}
}

export class GetUserDetailsSuccess {
    static readonly type = 'GET_USER_DETAILS_SUCCESS';

    constructor(public payload: UserDetails) {}
}

export class GetUserDetailsError {
    static readonly type = 'GET_USER_DETAILS_ERROR';

    constructor() {}
}


