import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
    selector: 'nw-amount-input',
    templateUrl: './amount-input.component.html',
    styleUrls: ['./amount-input.component.scss']
})
export class AmountInputComponent implements OnInit {
    currentValue: number;
    oldValue: number;
    @Input() min: number;
    @Input() max: number;
    @Input() step: number;
    @Output() valueChange = new EventEmitter();

    public errors: Array<String> = [];

    constructor() {
    }

    ngOnInit() {
    }

    private adjustInputValue(val) {
        if (val > this.max) {
            return this.max;
        }
        if (val < this.min) {
            return this.min;
        }
        if (val % 1000 !== 0) {
            return Math.trunc(val / 1000) * 1000;
        }

        return val;
    }

    get value() {
        return this.currentValue;
    }

    @Input()
    set value(val) {
        this.oldValue = this.currentValue;
        this.currentValue = val;
        this.valueChange.emit(this.currentValue);
    }

    onChange(amount: Number) {
        if (amount === this.oldValue) {
            return;
        }
        setTimeout(() => {
            this.value = this.adjustInputValue(amount);
        }, 0);
    }
}
