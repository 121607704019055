import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
    Attachment,
    BusinessPlanFormData,
    CompanyDetailsFormData,
    CurrentBorrowingFormData, DebtorFormData,
    DocumentsStepFormData,
    LoanApplicationDetailsFormData,
    NewLoanApplicationEntity,
    ShareholdersFormData
} from '@nw-app/modules/loan-application/loan-application.interfaces';
import { environment } from '@nw-root/environments/environment';


interface LoanApplicationFilesResponse {
    generated: Attachment[];
    regenerated: Attachment[];
    uploaded: { [key: string]: Attachment[] };
}



@Injectable()
export class LoanApplicationService {
    constructor(private http: HttpClient) {
    }

    saveLoanDetails(id: number | string, formData: LoanApplicationDetailsFormData) {
        return this.http.put<NewLoanApplicationEntity>(`${environment.apiBaseURL}/api/v1/loan_applications/details/${id}`, formData);
    }

    getLoan(id: number) {
        return this.http.get<NewLoanApplicationEntity>(`${environment.apiBaseURL}/api/v1/loan_applications/${id}`);
    }

    getAttachments(id: number) {
        return this.http.get<LoanApplicationFilesResponse>(`${environment.apiBaseURL}/api/v1/loan_applications/files/${id}`);
    }

    saveLoanCompanyDetails(id: number, formData: CompanyDetailsFormData) {
        return this.http.put<NewLoanApplicationEntity>(`${environment.apiBaseURL}/api/v1/loan_applications/company_details/${id}`, formData);
    }

    saveShareholdersDetails(id: number, formData: ShareholdersFormData) {
        return this.http.put<NewLoanApplicationEntity>(`${environment.apiBaseURL}/api/v1/loan_applications/directors_details/${id}`, formData);
    }

    saveBusinessPlan(id: number, formData: BusinessPlanFormData) {
        return this.http.put<NewLoanApplicationEntity>(`${environment.apiBaseURL}/api/v1/loan_applications/further_information/${id}`, formData);
    }

    saveCurrentBorrowing(id: number, formData: CurrentBorrowingFormData) {
        return this.http.put<NewLoanApplicationEntity>(`${environment.apiBaseURL}/api/v1/loan_applications/current_borrowing/${id}`, formData);
    }
    saveLoanDocumentsStep(id: number, formData: DocumentsStepFormData) {
        return this.http.put<string>(`${environment.apiBaseURL}/api/v1/loan_applications/document_step/${id}`, formData);
    }

    markApplicationAsFinished(id: number) {
        return this.http.put<string>(`${environment.apiBaseURL}/api/v1/loan_applications/finish_steps/${id}`, {});
    }

    downloadFile(url: string) {
        return this.http.get<Blob>(url, { observe: 'response', responseType: 'blob' as 'json' });
    }

    saveDebtor(id: number, formData: DebtorFormData) {
        return this.http.put<NewLoanApplicationEntity>(`${environment.apiBaseURL}/api/v1/loan_applications/details_of_debtors/${id}`, formData);
    }

    saveSuppliers(id: number, formData: DebtorFormData) {
        return this.http.put<NewLoanApplicationEntity>(`${environment.apiBaseURL}/api/v1/loan_applications/details_of_suppliers/${id}`, formData);
    }
}
