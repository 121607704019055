import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { EMPTY } from 'rxjs/internal/observable/empty';
import { Router } from '@angular/router';

import { TokenService } from '@nw-auth/services/token.service';
import shared from '../../../../../../../shared.json';
import { DefaultResponse } from '@nw-auth/authorization.interfaces';
import { environment } from '@nw-root/environments/environment';


@Injectable()
export class BrokerRegistrationService {
    private currentStep: Observable<string>;
    private currentStepSubject: Subject<string>;
    public readonly steps: string[];

    constructor(
        protected httpClient: HttpClient,
        protected storage: TokenService,
        protected router: Router,
    ) {
        this.currentStepSubject = new Subject<string>();
        this.currentStep = this.currentStepSubject.asObservable();
        this.steps = (shared as any).brokerSteps;
    }

    _getHeaders(headers = {}) {
        const authToken = this.storage.getTokenOrLogout();
        const tokenHeaders = {
            'register-token': this.storage.getRegisterToken('broker') || '',
        };
        if (authToken) {
            tokenHeaders['Authorization'] = `Bearer ${authToken}`;
        }
        return Object.assign({headers: tokenHeaders}, headers);
    }

    register(dataForm: any): Observable<any> {
        return this.httpClient.post<any>(`${environment.apiBaseURL}/api/v1/brokers`, dataForm);
    }

    getBrokerApplication(): Observable<any> {
        if (
            (this.storage.getRegisterToken('broker') && this.storage.getRegisterToken('broker') != 'undefined')
            || this.storage.getTokenOrLogout()
        ) {
            return this.httpClient.get<any>(
                `${environment.apiBaseURL}/api/v1/broker/apply`,
                this._getHeaders()
            );
        } else {
            return EMPTY;
        }
    }

    getHeardFromOptions(): Observable<any> {
        return null
        // broker managers from MambuAdminUser
        // this.httpClient.get<any>(
        //     `${environment.apiBaseURL}/api/v1/broker/options/heard_from/`,
        //     this._getHeaders()
        // );
    }

    registerBrokerCompanyDetails(dataForm: FormData): Observable<any> {
        return this.httpClient.post<any>(
            `${environment.apiBaseURL}/api/v1/broker/apply/company`,
            dataForm,
            this._getHeaders({'Content-Type': 'application/form-data'})
        );
    }

    registerBrokerOwners(dataForm: FormData): Observable<any> {
        return this.httpClient.post<any>(
            `${environment.apiBaseURL}/api/v1/broker/apply/owners`,
            dataForm,
            this._getHeaders({'Content-Type': 'application/form-data'})
        );
    }

    loadIntroducerAgreement(): Observable<any> {
        return this.httpClient.get<any>(
            `${environment.apiBaseURL}/api/v1/broker/agreement`
        );
    }

    registerAgreement(): Observable<any> {
        return this.httpClient.post<any>(
            `${environment.apiBaseURL}/api/v1/broker/apply/save_agreement`,
            null,
            this._getHeaders()
        );
    }

    submitApply(): Observable<any> {
        return this.httpClient.post<any>(
            `${environment.apiBaseURL}/api/v1/broker/apply`,
            null,
            this._getHeaders()
        );
    }

    getObservableStep(): Observable<string> {
        return this.currentStep;
    }

    setStep(step: string) {
        this.currentStepSubject.next(step);
    }

    canAccessStep(current_step: string, required_step) {
        if (current_step === 'FINISHED') {
            return true;
        }
        const req_step_index = this.steps.indexOf(required_step);
        const cur_step_index = this.steps.indexOf(current_step);

        return cur_step_index >= req_step_index;
    }

    getBrokerAgreementToken(token) {
        return this.httpClient.get<DefaultResponse>(`${environment.apiBaseURL}/api/v1/broker/cashflow-agreement/${token}`);
    }

    signBrokerAgreement(token) {
        return this.httpClient.put<DefaultResponse>(`${environment.apiBaseURL}/api/v1/broker/cashflow-agreement/${token}`, null);
    }
}
