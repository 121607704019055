import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'debug'
})
export class DebugPipe implements PipeTransform {

    transform(value: any, placeDebugger?: boolean): any {
        if (placeDebugger) {
            /* tslint:disable */
            debugger;
            /* tslint:enable */
        }
        return value;
    }

}
