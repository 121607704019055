import { catchError } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';

import { Store } from '@ngxs/store';
import { Observable, of } from 'rxjs';

import { getErrorMessage } from '@nw-app/utils/request';
import { UserFailLogin } from '@nw-auth/authorization.actions';
import { AuthorisationResponsePayload, LoginFormData, RefreshTokenResponsePayload, UserDetails } from '@nw-auth/authorization.interfaces';
import { RefreshService } from './refresh.service';
import { TokenService } from './token.service';
import { environment } from '@nw-root/environments/environment';

@Injectable()
export class AuthorizationService {
    unregisterCallback: Function;

    constructor(
        private httpClient: HttpClient, private store: Store, private refresher: RefreshService,
        private tokenService: TokenService, private router: Router, private ngZone: NgZone,
    ) {
    }

    login(credentials: LoginFormData, admin = false): Observable<AuthorisationResponsePayload> {
        if (this.unregisterCallback) {
            this.unregisterCallback();
        }

        const payload = new FormData();
        payload.append('username', credentials.email)
        payload.append('password', credentials.password)
        payload.append('scope', 'broker')
        if (admin) {
            payload.set('scope', 'admin')
        }

        return this.httpClient.post<AuthorisationResponsePayload>(`${environment.apiBaseURL}/api/v1/auth`, payload);
    }

    refreshToken() {
        const token = this.tokenService.getTokenOrLogout();

        if (!token) {
            this.store.dispatch(new UserFailLogin('Authorisation token not found.'));
        } else {
            this.httpClient.get<RefreshTokenResponsePayload>(`${environment.apiBaseURL}/api/v1/refresh`, {
                headers: new HttpHeaders().set('Authorization', `Bearer ${token}`)
            }).pipe(
                catchError(err => {
                    this.store.dispatch(new UserFailLogin(getErrorMessage(err)));
                    return of({ access_token: null });
                }))
                .subscribe(response => {
                    if (response.access_token) {
                        this.tokenService.updateToken(response.access_token);
                    }
                });
        }
    }

    protected isTokenSet() {
        return !!this.tokenService.getTokenOrLogout();
    }

    setUpRefreshing(force = false) {
        if (this.unregisterCallback) {
            this.unregisterCallback();
        }

        if (force || this.isTokenSet()) {
            this.unregisterCallback = this.refresher.register(
                () => this.refreshToken(), 5 * 60, 30 * 60, 50
            );
        }
    }

    redirectToLogin() {
        let url = 'login';
        if (this.router.url.indexOf('admin') > 0) {
            url = `admin/login`;
        }
        this.ngZone.run(() => this.router.navigate([url], { queryParams: { next: this.router.url } }));
    }

    getUserData() {
        const token = this.tokenService.getTokenOrLogout();
        const tokenPayload = this.tokenService.decodeToken(token);
        return this.httpClient.get<UserDetails>(`${environment.apiBaseURL}/api/v1/users/${tokenPayload.sub}`);
    }
}
