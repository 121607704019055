import { Inject, LOCALE_ID, Pipe, PipeTransform } from '@angular/core';
import { PercentPipe } from '@angular/common';


@Pipe({
    name: 'nwPercent'
})
export class NwPercentPipe implements PipeTransform {
    constructor(@Inject(LOCALE_ID) private _locale: string) {
    }

    transform(data: any): string {
        if (data === 'No data') {
            return data;
        }

        if (typeof data === 'number') {
            data = data / 100;
        }

        return (new PercentPipe(this._locale)).transform(data, '1.0-2');
    }
}
