import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'nw-contact-footer',
    templateUrl: './contact-footer.component.html',
    styleUrls: [
        './contact-footer.component.scss'
    ]
})
export class ContactFooterComponent implements OnInit {
    @Input() nf: boolean;

    constructor() {
    }

    ngOnInit() {
    }
}
