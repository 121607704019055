<div *ngIf="!cbils" class="covid row">
    <div class="hidden-xs col-sm-1">
        <i class="fa fa-info-circle" aria-hidden="true"></i>
    </div>
    <div class="content col-sm-11">
        Due to COVID-19 we have taken precautionary measures and temporarily our teams are working
        remotely.
        Services will not be affected, however, we are advising to make contact by emailing
        <a href="mailto:support@newable.awsapps.com">support@newable.awsapps.com</a>. Should you
        wish to speak
        to a member of the team via telephone, please state this in your email and one of our team
        will contact you.
    </div>
</div>
<div class="covid row">
    <div class="hidden-xs col-sm-1">
        <i class="fa fa-info-circle" aria-hidden="true"></i>
    </div>
    <div *ngIf="!broker && !introducer" class="content col-sm-11">
        Following the latest revision to the Government’s Coronavirus Business Interruption Loan
        Scheme (CBILS) which has removed the ability of lenders to take Personal Guarantees from
        loan applicants for facilities of less than £250,000, Newable has taken the difficult
        decision to pause lending under the scheme until further notice.
        Newable’s own funding agreements prohibit us from participating under the new terms of
        CBILS. We regret that the changes to CBILS mean that Newable finds that it now cannot use
        the scheme as a way of assisting many viable UK businesses through the current difficult
        circumstances. We advise for you to approach alternative lenders
        at <a
        href="https://www.british-business-bank.co.uk/ourpartners/coronavirus-business-interruption-loan-scheme-cbils-2/current-accredited-lenders-and-partners/">
        https://www.british-business-bank.co.uk/ourpartners/coronavirus-business-interruption-loan-scheme-cbils-2/current-accredited-lenders-and-partners/</a>
    </div>
    <div *ngIf="broker" class="content col-sm-11">
        <p>Unfortunately, due to restrictions which have been imposed upon us by our primary
            funders
            in the current exceptional circumstances, Newable will not be accepting any new loan
            applications at this time.
        </p>
        <p>We hope that these restrictions are temporary and further updates will be communicated
            to
            our introducer network once the situation changes.
        </p>
        <p>If you have any questions, please contact us at <a href="mailto:lending@newable.co.uk">lending@newable.co.uk</a>
        </p>
        For the Government’s Coronavirus Business Interruption Loan Scheme (CBILS) we advise for
        you to approach alternative lenders at
        <a href="https://www.british-business-bank.co.uk/ourpartners/coronavirus-business-interruption-loan-scheme-cbils-2/current-accredited-lenders-and-partners/">
            https://www.british-business-bank.co.uk/ourpartners/coronavirus-business-interruption-loan-scheme-cbils-2/current-accredited-lenders-and-partners/</a>
    </div>
    <div *ngIf="introducer" class="content col-sm-11">
        <p>Following the latest revision to the Government’s Coronavirus Business Interruption Loan
            Scheme (CBILS) which has removed the ability of lenders to take Personal Guarantees
            from
            loan applicants for facilities of less than £250,000, Newable has taken the difficult
            decision to pause lending as own funding agreements prohibit us from participating
            under
            the new terms of CBILS.</p>

        <p>We hope that these restrictions are temporary, however, as a result of the above, we are
            not currently accepting new broker registrations.</p>
    </div>
</div>

