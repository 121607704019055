import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewEncapsulation } from '@angular/core';
import shared from '@nw-root/../../shared.json';
import { AuthorizationState } from '@nw-auth/authorization.state';
import { AuthorizationStateModel } from '@nw-auth/authorization.interfaces';
import { Observable } from 'rxjs';
import { Select } from '@ngxs/store';

@Component({
    encapsulation: ViewEncapsulation.None,
    selector: 'nw-amount-slider',
    templateUrl: './amount-slider.component.html',
    styleUrls: ['./amount-slider.component.scss']
})
export class AmountSliderComponent implements OnInit, OnChanges {
    public monthsSelectorData: { name, value }[];

    currentAmount = 0;

    public min: number;
    public max: number;
    public period = 0;
    public step = 1000;
    public arrangementFee = 0;
    public monthlyRepayments = 0;
    public totalLoanRepayment = 0;
    private interestRate = 0.16;
    private arrangementFeePercent = 4;
    private shared: any;

    @Select(AuthorizationState.getUser) user$: Observable<AuthorizationStateModel>;

    constructor() {
        this.shared = shared;
    }

    ngOnInit() {
        this.min = this.shared.amount_limits.NBF.min;
        this.max = this.shared.amount_limits.NBF.max;
        this.monthsSelectorData = [
            {name: '1 year', value: 12},
            {name: '2 years', value: 24},
            {name: '3 years', value: 36},
            {name: '4 years', value: 48},
            {name: '5 years', value: 60}
        ];
        this.amount = this.min;
        this.period = this.monthsSelectorData[0].value;
    }

    get amount() {
        return this.currentAmount;
    }

    @Input()
    set amount(val) {
        this.currentAmount = val;
        this.recalculateValues();
    }

    ngOnChanges(changes: SimpleChanges) {
        this.recalculateValues();
    }

    recalculateValues() {
        this.monthlyRepayments = this.userMonthlyPayment();
        this.totalLoanRepayment = this.monthlyRepayments * this.period;
        this.arrangementFee = this.getArrangementFee();
    }

    sliderChange(e) {
        this.amount = e.value;
        this.recalculateValues();
    }

    monthsChange(e) {
        this.recalculateValues();
    }

    getArrangementFee() {
        return this.amount * this.arrangementFeePercent / 100;
    }

    userMonthlyPayment() {
        let monthlyPayment = 0;
        const period = this.period;
        const factor = this.userMonthlyRate();

        monthlyPayment = this.amount * (
            factor * Math.pow(1 + factor, period)
        ) / (Math.pow(1 + factor, period) - 1);

        return isNaN(monthlyPayment) ? 0 : (Math.round(monthlyPayment * 100) / 100);
    }

    userMonthlyRate() {
        return this.interestRate / 12;
    }
}
