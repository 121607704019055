<div class="nav-wrapper sticky-top">
    <nav class="navbar navbar-expand-lg navbar-light justify-content-between container">
        <div class="back-to-newable-co-u">
            <a href="https://commerce.newable.co.uk/">
                <i class="fa fa-angle-left" aria-hidden="true"></i>
                Visit Commerce.newable.co.uk
            </a>
        </div>

        <div class="login-bar" *ngIf="!(user$ | async).isLoggedIn">
            <a routerLink="{{isNcf ? '/nf/login' : '/login' }}">
                <i class="fa fa-user-circle-o" aria-hidden="true"></i>
                Log in
            </a>
            <span class="separator" *ngIf="!isNcf">|</span>
            <a routerLink="/register" *ngIf="!isNcf">Register</a>
        </div>

        <div class="login-bar" *ngIf="(user$ | async).isLoggedIn">
            <a routerLink="{{isNcf ? '/nf/loans' : '/loans' }}">
                <i class="fa fa-user-circle-o" aria-hidden="true"></i>
                {{ (user$ | async).displayedName }}
            </a>
            <span class="separator">|</span>
            <a (click)="logout()">Logout</a>
        </div>
    </nav>
</div>
