import { Injectable } from '@angular/core';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { PopupComponent } from '../components/popup/popup.component';


@Injectable()
export class CustomPopupService {

    constructor(private modalService: NgbModal) {
    }

    open(title, content, refresh=false) {
        const ngbModalOptions: NgbModalOptions = {
            backdrop : refresh ? 'static' : true,
            keyboard : refresh ? true : false
        };
        const modalRef = this.modalService.open(PopupComponent, ngbModalOptions);
        modalRef.componentInstance.modalTitle = title;
        modalRef.componentInstance.modalContent = content;
        modalRef.componentInstance.modalRefresh = refresh;
    }

    showUnexpectedError() {
        this.open(
            'Unexpected error has occurred!',
            ['Our team is aware of the problem and is working to fix it. Please try again later!']
        );
    }

    showAppRefreshError() {
        this.open(
            'Newable website was updated',
            [
                'Further work is impossible due to application version incompatibility.',
                'Please refresh the page. Any unsaved details will be lost.'
            ],
            true
        );
    }

    showMissingDocsError(content) {
        this.open(
            'Documents could not be regenerated',
            [content],
        );
    }
}
