import { EligibilityCheckData, EligibilityCheckResponse } from './eligibility.interfaces';

export class GetEligibilityCheck {
    static readonly type = 'GET_ELIGIBILITY_CHECK';

    constructor(public payload: EligibilityCheckData) {
    }
}

export class GetEligibilityCheckSuccess {
    static readonly type = 'GET_ELIGIBILITY_SUCCESS';

    constructor(public payload: EligibilityCheckResponse) {
    }
}

export class GetEligbilityCheckError {
    static readonly type = 'GET_ELIGIBILITY_ERROR';

    constructor(public error: string) {
    }
}

export class ResetEligibility {
    static readonly type = 'RESET_ELIGIBILITY';

    constructor() {
    }
}

export class GetElibigilityDataFromLocalStorage {
    static readonly type = 'GET_ELIGIBILITY_DATA_FROM_LOCAL_STORAGE';

    constructor() {
    }
}
