import { Injectable } from '@angular/core';

@Injectable()
export class ErrorPageService {
    public static browserStorageErrors = [
        // Mozilla Firefox
        `window.localStorage is null`,
        `storage is null`,
        `The operation is insecure.`,
        // Google Chrome
        `Security Error: Failed to read the 'localStorage' property from 'Window': Access is denied for this document.`,
        `Failed to read the 'localStorage' property from 'Window': Access is denied for this document.`,
        // IE11
        `Unable to get property 'getItem' of undefined or null reference`,
        `Unable to get property 'setItem' of undefined or null reference`,
    ]

    protected errors: Array<String> = [];

    constructor() { }

    public checkLocalStorageAvailability() {
        try {
            this.testStorage();
        } catch (e) {
            if (!ErrorPageService.browserStorageErrors.includes(e.message)) {
                throw e;
            } else {
                const errorMessage = 'Local storage is not available in your browser: ' +
                    'For our website to work correctly, please try a different browser, or enable ' +
                    'local storage in your browser options.';
                this.addError(errorMessage);
            }
        }
    }

    public testStorage() {
        const storage = window.localStorage,
        item = '__storage_test__';
        window.localStorage.setItem(item, item);
        window.localStorage.removeItem(item);
    }

    public addError(errorMessage: String) {
        this.errors.push(errorMessage);
    }

    public getErrors(): Array<String> {
        return this.errors;
    }

    public hasErrors(): boolean {
        return !!this.errors.length;
    }
}
