import { Inject, LOCALE_ID, Pipe, PipeTransform } from '@angular/core';
import { CurrencyPipe } from '@angular/common';


@Pipe({
    name: 'nwCurrency'
})
export class NwCurrencyPipe implements PipeTransform {
    constructor(@Inject(LOCALE_ID) private _locale: string) {
    }

    transform(data: number | string, currencyCode = 'GBP'): string {
        if (data === 'No data') {
            return data;
        }

        if (typeof data === 'string' && Number.isNaN(parseFloat(data))) {
            return data;
        }
        return (new CurrencyPipe(this._locale)).transform(data, currencyCode, 'symbol', '1.0-2');
    }
}
