import { Action, Selector, State, StateContext } from '@ngxs/store';
import { BrokerRegistrationEntity, CompaniesHouseDetails } from '@nw-registration/modules/broker/broker-registration.interfaces';
import { BrokerRegistrationService } from '@nw-registration/modules/broker/broker-registration.service';
import {
    BrokerCompanyRegister,
    BrokerCompanyRegisterError,
    BrokerCompanyRegisterSuccess,
    BrokerRegister,
    BrokerRegisterAgreement,
    BrokerRegisterAgreementError,
    BrokerRegisterAgreementSuccess,
    BrokerRegisterError,
    BrokerRegisterOwners,
    BrokerRegisterOwnersError,
    BrokerRegisterOwnersSuccess,
    BrokerRegisterSuccess,
    BrokerSubmitApply,
    BrokerSubmitApplyError,
    BrokerSubmitApplySuccess,
    CompaniesHouseDetailsError,
    CompaniesHouseDetailsSuccess,
    CompaniesHousePartialDetails,
    GetBrokerApplication,
    GetBrokerApplicationError,
    GetBrokerApplicationSuccess,
    GetCompaniesHouseDetails,
    GetHeardFrom,
    ValidateNumberError
} from '@nw-registration/modules/broker/broker-registration.actions';
import { getErrorMessage } from '@nw-app/utils/request';
import { ActivatedRoute, Router } from '@angular/router';
import { CompanyFinderService } from '@nw-common/services/company-finder.service';
import { Injectable } from '@angular/core';

export const defaultBrokerApplicationState = {
    brokerApplication: null,
    error: null,
    heardFrom: null,
    companiesHouseDetails: {
        directorsSearch: [],
        companyAddress: null,
        legalStructure: null,
        businessName: null,
        registrationNumber: null
    },
    mainPath: null
};

export interface BrokerApplicationStateModel {
    brokerApplication: BrokerRegistrationEntity;
    error: string;
    heardFrom: any;
    companiesHouseDetails: CompaniesHouseDetails;
}

@State<BrokerApplicationStateModel>({
    name: 'brokerRegistration',
    defaults: { ...defaultBrokerApplicationState }
})
@Injectable()
export class BrokerApplicationState {
    constructor(private brokerService: BrokerRegistrationService, private router: Router,
                private companyFinderService: CompanyFinderService, public route: ActivatedRoute) {
    }

    @Selector()
    static getBrokerStep(state: BrokerApplicationStateModel) {
        return state.brokerApplication.step;
    }

    @Selector()
    static brokerApplication(state: BrokerApplicationStateModel) {
        return state.brokerApplication;
    }

    @Selector()
    static hearFrom(state: BrokerApplicationStateModel) {
        return state.heardFrom;
    }

    @Selector()
    static directors(state: BrokerApplicationStateModel) {
        return state.companiesHouseDetails.directorsSearch;
    }

    @Selector()
    static companyAddress(state: BrokerApplicationStateModel) {
        return state.companiesHouseDetails.companyAddress;
    }

    @Selector()
    static companyDetails(state: BrokerApplicationStateModel) {
        return state.companiesHouseDetails;
    }

    @Action(GetBrokerApplication)
    getBrokerApplication(ctx: StateContext<BrokerApplicationStateModel>) {
        return this.brokerService.getBrokerApplication().subscribe({
            next: response => {
                return ctx.dispatch(new GetBrokerApplicationSuccess(response));
            },
            error: error => {
                ctx.dispatch(new GetBrokerApplicationError(error.error?.detail));
                if (error.status === 403 || error.status === 422 || error.status === 401) {
                    return this.router.navigate(['/loans']);
                }
            }
        });
    }

    @Action(GetBrokerApplicationSuccess)
    getBrokerApplicationSuccess(ctx: StateContext<BrokerApplicationStateModel>, action: GetBrokerApplicationSuccess) {
        ctx.patchState({
            brokerApplication: action.payload,
            error: null
        });
    }

    @Action(GetBrokerApplicationError)
    getBrokerApplicationError(ctx: StateContext<BrokerApplicationStateModel>, action: GetBrokerApplicationError) {
        ctx.patchState({
            error: action.error
        });
    }

    @Action(BrokerRegister)
    brokerRegister(ctx: StateContext<BrokerApplicationStateModel>, action: BrokerRegister) {
        this.brokerService.register(action.payload).subscribe({
            next: (response: {register_token: string}) => {
                ctx.dispatch(new GetBrokerApplication());
                ctx.dispatch(new BrokerRegisterSuccess(response.register_token));
            },
            error: (error) => {
                ctx.dispatch(new BrokerRegisterError(getErrorMessage(error)));
            }
        });
    }

    @Action(GetHeardFrom)
    getHeardFrom(ctx: StateContext<BrokerApplicationStateModel>) {
        // this.brokerService.getHeardFromOptions().subscribe(
        //     response => {
        //         ctx.patchState({
        //             heardFrom: response
        //         });
        //     },
        // );
    }

    @Action(BrokerCompanyRegister)
    brokerCompanyRegister(ctx: StateContext<BrokerApplicationStateModel>, action: BrokerCompanyRegister) {
        this.brokerService.registerBrokerCompanyDetails(action.payload).subscribe({
            next: () => {
                ctx.dispatch(new GetBrokerApplication());
                ctx.dispatch(new BrokerCompanyRegisterSuccess());
            },
            error: error => {
                ctx.dispatch(new BrokerCompanyRegisterError(getErrorMessage(error)));
            }
        });
    }

    @Action(BrokerRegisterOwners)
    brokerRegisterOwners(ctx: StateContext<BrokerApplicationStateModel>, action: BrokerRegisterOwners) {
        this.brokerService.registerBrokerOwners(action.payload).subscribe({
            next: () => {
                ctx.dispatch(new GetBrokerApplication());
                ctx.dispatch(new BrokerRegisterOwnersSuccess());
            },
            error: error => {
                ctx.dispatch(new BrokerRegisterOwnersError(getErrorMessage(error)));
            }
        });
    }

    @Action(BrokerRegisterAgreement)
    brokerRegisterAgreement(ctx: StateContext<BrokerApplicationStateModel>, action: BrokerRegisterAgreement) {
        this.brokerService.registerAgreement().subscribe({
            next: () => {
                ctx.dispatch(new GetBrokerApplication());
                ctx.dispatch(new BrokerRegisterAgreementSuccess());
            },
            error: error => {
                ctx.dispatch(new BrokerRegisterAgreementError(getErrorMessage(error)));
            }
        });
    }

    @Action(BrokerSubmitApply)
    brokerSubmitApply(ctx: StateContext<BrokerApplicationStateModel>, action: BrokerSubmitApply) {
        this.brokerService.submitApply().subscribe({
            next: () => {
                ctx.dispatch(new BrokerSubmitApplySuccess());
            },
            error: error => {
                ctx.dispatch(new BrokerSubmitApplyError(getErrorMessage(error)));
            }
        });
    }

    @Action(GetCompaniesHouseDetails)
    getCompaniesHouse(ctx: StateContext<BrokerApplicationStateModel>, action: GetCompaniesHouseDetails) {
        this.companyFinderService.get(action.registration_number).subscribe({
            next: data => {
                if (!action.validate) {
                    ctx.dispatch(new CompaniesHouseDetailsSuccess(data));
                }
            },
            error: () => {
                if (action.validate) {
                    ctx.dispatch(new ValidateNumberError());
                } else {
                    ctx.dispatch(new CompaniesHouseDetailsError());
                }
            }
        });
    }

    @Action(CompaniesHouseDetailsSuccess)
    companiesHouseDetails(ctx: StateContext<BrokerApplicationStateModel>, action: CompaniesHouseDetailsSuccess) {
        ctx.patchState({
            companiesHouseDetails: {
                directorsSearch: action.payload.directors,
                companyAddress: action.payload.legal_address,
                legalStructure: action.payload.type,
                businessName: action.payload.company_legal_name,
                registrationNumber: action.payload.company_registration_number
            }
        });
    }

    @Action(CompaniesHousePartialDetails)
    companyAddress(ctx: StateContext<BrokerApplicationStateModel>, action: CompaniesHousePartialDetails) {
        ctx.patchState({
            companiesHouseDetails: {
                directorsSearch: null,
                companyAddress: action.address,
                registrationNumber: action.registrationNumber,
                businessName: action.businessName,
                legalStructure: null
            }
        });
    }
}
