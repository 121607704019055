import { Component, OnInit } from '@angular/core';

import { Store } from '@ngxs/store';

import { AuthorizationService } from '@nw-auth/services/authorization.service';
import { UserLogoutOnOtherTab } from '@nw-auth/authorization.actions';
import { Router } from '@angular/router';
import { ErrorPageService } from '@nw-common/services/error-page.service';

@Component({
    selector: 'nw-root',
    template: `<router-outlet></router-outlet>`,
    styleUrls: []
})
export class RootComponent implements OnInit {

    constructor(private store: Store, private authService: AuthorizationService,
        private router: Router, private errorPageService: ErrorPageService) {}

    ngOnInit() {
        this.redirectIfErrors();

        window.addEventListener(
            'storage',
            tabCloser.bind(null, this.store),
            false
        );

        this.authService.setUpRefreshing();
    }

    redirectIfErrors() {
        if (this.errorPageService.hasErrors()) {
            this.router.navigateByUrl('error');
        }
    }
}

function tabCloser(store, event) {
    const tokenKey = 'accessToken';

    if (event.key === tokenKey) {
        if (!event.newValue) {
            /* LOGOUT action on other tab in browser */
            store.dispatch(new UserLogoutOnOtherTab());
        }
    }
}
