import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'nw-menu-default',
    templateUrl: './menu-default.component.html',
    styleUrls: ['../styles/common.menu.scss']
})
export class MenuDefaultComponent implements OnInit {
    home: string;
    constructor() { }

    ngOnInit() {
        this.home = '/introducers';
    }
}
