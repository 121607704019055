import { Injectable } from '@angular/core';
import { CompanyFinderDetailedResponse, CompanyFinderSimpleResponse } from '@nw-common/common.interfaces';
import { HttpClient } from '@angular/common/http';
import { catchError, map, share } from 'rxjs/operators';
import { Observable, of, throwError } from 'rxjs';
import { environment } from '@nw-root/environments/environment';

@Injectable()
export class CompanyFinderService {
    private data: { [key: string]: CompanyFinderDetailedResponse } = {};
    private get$: { [key: string]: Observable<CompanyFinderDetailedResponse> } = {};

    constructor(private http: HttpClient) {
    }

    find(query: string) {
        return this.http.get<CompanyFinderSimpleResponse[]>(`${environment.apiBaseURL}/api/v1/company_finder/find?query=${query}`);
    }

    get(registration_number: string) {
        return this.http.get<CompanyFinderDetailedResponse>(`${environment.apiBaseURL}/api/v1/company_finder/get/${registration_number}`);
    }

    getFromCompany(company: CompanyFinderSimpleResponse) {
        return this.http.get<CompanyFinderDetailedResponse>(`${environment.apiBaseURL}/api/v1/company_finder/get/${company.company_number}`).pipe(
            catchError(err => throwError(() => company))
        );
    }

}
