<li class="nav-item level-top">
    <a class="nav-link" routerLink="/loans">
        <div class="inner-link">My Dashboard</div>
    </a>
</li>
<li class="nav-item level-top">
    <a class="nav-link" routerLink="/coworkers">
        <div class="inner-link">Manage Coworkers</div>
    </a>
</li>
