import { Component, Input } from '@angular/core';

import { SubmenuItem } from '@nw-common/interfaces/commons.interface';


@Component({
    selector: 'nw-header-banner',
    templateUrl: './header-banner.component.html',
    styleUrls: [
        './header-banner.component.scss'
    ]
})
export class HeaderBannerComponent {
    @Input() title: string;
    @Input() image: string;
    @Input() submenuItems: SubmenuItem[];

    constructor() {
    }
}
