import { Component, Input } from '@angular/core';

import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';

import { UserLogout } from '@nw-auth/authorization.actions';
import { AuthorizationState } from '@nw-auth/authorization.state';
import { AuthorizationStateModel } from '@nw-auth/authorization.interfaces';


@Component({
    selector: 'nw-login-header',
    templateUrl: './login-header.component.html',
    styleUrls: ['./login-header.component.scss']
})
export class LoginHeaderComponent {
    @Input() isNcf: boolean;
    @Select(AuthorizationState.getUser) user$: Observable<AuthorizationStateModel>;

    constructor(private store: Store) {
    }

    logout() {
        this.store.dispatch(new UserLogout());
    }
}
