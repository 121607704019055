import { Action, Selector, State, StateContext } from '@ngxs/store';
import { CookiesBanner } from '@nw-common/actions/commons.actions';
import { Injectable } from '@angular/core';

export const defaultCommonsState = {
    cookieBannerVisible: null
};

export interface CommonsStateModel {
    cookieBannerVisible: boolean;
}

@State<CommonsStateModel>({
    name: 'commons',
    defaults: { ...defaultCommonsState }
})
@Injectable()
export class CommonsState {

    @Selector()
    static cookieBannerVisible(state: CommonsStateModel) {
        return state.cookieBannerVisible;
    }

    @Action(CookiesBanner)
    setCookiesBanner(ctx: StateContext<CommonsStateModel>, action: CookiesBanner) {
        ctx.patchState({
            cookieBannerVisible: action.payload
        });
    }
}
