import { Pipe, PipeTransform } from '@angular/core';


@Pipe({
    name: 'nwPeriod'
})
export class NwPeriodPipe implements PipeTransform {

    transform(months: number): string {
        if (months === 1) {
            return `${months} month`;
        }
        if (months <= 12) {
            return `${months} months`;
        }

        let monthsCounter = months;
        let yearsCounter = 0;

        while (monthsCounter >= 12) {
            if (monthsCounter % 12 >= 0) yearsCounter++;
            monthsCounter -= 12;
        }
        return `${yearsCounter > 1 ? `${yearsCounter} years` : '1 year'}${monthsCounter > 0 ? ` and ${monthsCounter > 1 ? `${monthsCounter} months` : '1 month'}` : ''}`;
    }
}
