<div class="header-banner">
    <div class="container title-box" [style.backgroundImage]="'url(' + image + ')'">
        <span class="title">{{ title }}</span>
        <div class="blue-line"></div>
    </div>
    <div class="container submenu">
        <span *ngFor="let item of submenuItems">
            <a *ngIf=!item.href routerLink="{{ item.url }}" fragment="{{ item.fragment}}">{{ item.title }}</a>
            <a *ngIf=item.href href="{{ item.url }}">{{ item.title }}</a>
        </span>
    </div>
</div>
