/** angular libs/modules */
import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, ErrorHandler, Injectable, NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';

/** Third part libs */
import { CookieService } from 'ngx-cookie-service';
import { JWT_OPTIONS, JwtModule, JwtConfig } from '@auth0/angular-jwt';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { NgxsModule } from '@ngxs/store';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';

/** Main module configs/extensions/configurations */
import { AppRoutingModule } from './app-routing.module';
import { environment } from '../environments/environment';
import { AppVersionInterceptor, HttpErrorInterceptor, SessionTokenInterceptor } from './app.interceptors';

/** Main module components */
import { AppComponent } from './components/app/app.component';
import { FooterComponent } from './components/footer/footer.component';
import { HeaderComponent } from './components/header/header.component';
import { LoginHeaderComponent } from './components/login-header/login-header.component';
import { MenuLoggedInComponent } from './components/menu-logged-in/menu-logged-in.component';
import { MenuDefaultComponent } from './components/menu-default/menu-default.component';
import { MenuLoginComponent } from './components/menu-login/menu-login.component';
import { RootComponent } from './components/root/root.component';

/** Other custom-modules */
import { NwCommonModule } from '@nw-common/nw-common.module';
import { ScriptsService } from '@nw-common/services/scripts.service';
import { WindowRef } from '@nw-common/services/app.window.service';
import { TokenService } from '@nw-auth/services/token.service';
import { ErrorPageService } from '@nw-common/services/error-page.service';
import { AuthorizationService } from '@nw-auth/services/authorization.service';
import { RefreshService } from '@nw-auth/services/refresh.service';
import { AuthorizationState } from '@nw-auth/authorization.state';
import { NgxsStoragePluginModule } from '@ngxs/storage-plugin';
import { NgxsFormPluginModule } from '@ngxs/form-plugin';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgOptimizedImage } from '@angular/common';


export function jwtOptionsFactory(tokenService: TokenService) {
  return {
    tokenGetter: () => tokenService.getTokenOrLogout(),
    allowedDomains: [
        'localhost:8000',
        'api.dev.newablelending.co.uk',
        'api.newablelending.co.uk'
    ],
    authScheme: 'Bearer '
  } as JwtConfig;
}

export function localStorageFactory(errorPageService: ErrorPageService) {
  return () => errorPageService.checkLocalStorageAvailability();
}

@NgModule({
  declarations: [
    RootComponent,
    AppComponent,
    HeaderComponent,
    FooterComponent,
    LoginHeaderComponent,
    MenuLoginComponent,
    MenuLoggedInComponent,
    MenuDefaultComponent
  ],
    imports: [
        NgxsModule.forRoot([
            AuthorizationState,
        ]),
        NgxsStoragePluginModule.forRoot({
            key: ['authorization', 'eligibility'],
        }),
        NgxsReduxDevtoolsPluginModule.forRoot(),
        JwtModule.forRoot({
            jwtOptionsProvider: {
                provide: JWT_OPTIONS,
                useFactory: jwtOptionsFactory,
                deps: [TokenService]
            }
        }),
        NgIdleKeepaliveModule.forRoot(),
        NwCommonModule,
        HttpClientModule,
        BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        NgxsFormPluginModule.forRoot(),
        NgOptimizedImage,
    ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: localStorageFactory,
      deps: [ErrorPageService],
      multi: true
    },
    CookieService,
    ErrorPageService,
    AuthorizationService,
    RefreshService,
    TokenService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: SessionTokenInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AppVersionInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true
    },
    {
      provide: WindowRef,
      useValue: window
    }
  ],
  exports: [],
  bootstrap: [
    RootComponent,
  ]
})
export class AppModule {
  constructor(scripts: ScriptsService) {
    scripts.insertScripts();
  }
}
