import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { JwtHelperService } from '@auth0/angular-jwt';
import { UserLogout } from '@nw-auth/authorization.actions';
import { AuthorisationResponsePayload } from '@nw-auth/authorization.interfaces';
import { ErrorPageService } from '@nw-common/services/error-page.service';

@Injectable()
export class TokenService {
    protected jwtHelper: JwtHelperService;
    protected tokenKeyName = 'accessToken';
    protected tokenStore;
    protected registerTokenStore;

    constructor(private store: Store) {
        this.jwtHelper = new JwtHelperService();

        try {
            this.tokenStore = window.localStorage;
            this.registerTokenStore = window.sessionStorage;
        } catch (e) {
            if (!ErrorPageService.browserStorageErrors.includes(e.message)) {
                throw e;
            }
        }
    }

    getTokenOrLogout() {
        const token = this._get();

        if (!token) {
            return null;
        }

        if (!this.jwtHelper.isTokenExpired(token)) {
            return token;
        } else {
            this.store.dispatch(new UserLogout());
        }
    }

    setAccessToken(resp: AuthorisationResponsePayload) {
        this._set(resp.access_token);
    }

    updateToken(token: string) {
        this._set(token);
    }

    decodeToken(token: string) {
        return this.jwtHelper.decodeToken(token);
    }

    logout() {
        this._delete();
    }

    protected _get() {
        return this.tokenStore.getItem(this.tokenKeyName);
    }

    protected _set(token: string) {
        this.tokenStore.setItem(this.tokenKeyName, token);
    }

    protected _delete() {
        this.tokenStore.removeItem(this.tokenKeyName);
    }

    setRegisterToken(registrationType, token) {
        this.registerTokenStore.setItem(`register_${registrationType}`, token);
    }

    getRegisterToken(registrationType) {
        return this.registerTokenStore.getItem(`register_${registrationType}`);
    }

    deleteRegisterToken(registrationType) {
        return this.registerTokenStore.removeItem(`register_${registrationType}`);
    }
}
