<li class="nav-item user-bar nav-login">
    <div class="nav-link" *ngIf="!(user$ | async)?.isLoggedIn">
        <a routerLink="/login">
            <i class="fa fa-user-circle-o" aria-hidden="true"></i>
            Log in
        </a>
        <span class="separator">|</span>
        <a routerLink="/register">Register</a>
    </div>
    <div class="nav-link" *ngIf="(user$ | async)?.isLoggedIn">
        <a routerLink="/loans">
            <i class="fa fa-user-circle-o" aria-hidden="true"></i>
            {{ (user$ | async).displayedName }}
        </a>
        <span class="separator">|</span>
        <a (click)="logout()">Logout</a>
    </div>
</li>
