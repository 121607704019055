import { Injectable } from '@angular/core';
import { saveAs } from 'file-saver';


@Injectable()
export class FileSaverService {

    constructor() {
    }

    saveAs(blob, filename) {
        saveAs(blob, filename);
    }
}
