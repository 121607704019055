import { ActivatedRoute, Router } from '@angular/router';
import { Injectable, NgZone } from '@angular/core';

import { Action, Selector, State, StateContext, Store } from '@ngxs/store';

import { getErrorMessage } from '@nw-app/utils/request';

import { AuthorisationErrorResponse, AuthorisationResponsePayload, AuthorizationStateModel } from './authorization.interfaces';
import {
    GetEligibilityCheckData,
    GetUserDetails,
    GetUserDetailsError,
    GetUserDetailsSuccess,
    UserFailLogin,
    UserLogin,
    UserLogout,
    UserLogoutOnOtherTab,
    UserNotConfirmed,
    UserSuccessLogin
} from './authorization.actions';
import { AuthorizationService } from './services/authorization.service';
import { TokenService } from './services/token.service';
import { RefreshService } from './services/refresh.service';
import { defaultLoansApplicationState } from '@nw-app/modules/loan-application/loan-application.state';
import { defaultLoansState } from '@nw-loans/loans.state';
import { defaultBrokerApplicationState } from '@nw-registration/modules/broker/broker-registration.state';

export const defaultAuthorizationState = {
    displayedName: '',
    firstName: '',
    isLoggedIn: false,
    errorMessage: null,
    userNotConfirmed: null,
    email: null,
    userName: '',
    role: '',
    eligibility: null,
    details: null,
    broker_application_finished: false,
    is_ncf: null,
    is_nbl: null,
};

@State<AuthorizationStateModel>({
    name: 'authorization',
    defaults: { ...defaultAuthorizationState },
})
@Injectable()
export class AuthorizationState {
    constructor(
        private authorizationService: AuthorizationService, private tokenService: TokenService,
        private router: Router, private route: ActivatedRoute, private refreshService: RefreshService,
        private store: Store, private ngZone: NgZone,
    ) {
    }

    @Selector()
    static getUser(state: AuthorizationStateModel) {
        return state;
    }

    @Selector()
    static getErrorMessage(state: AuthorizationStateModel) {
        return state.errorMessage;
    }

    @Selector()
    static getUserNotConfirmed(state: AuthorizationStateModel) {
        return state.userNotConfirmed;
    }

    @Selector()
    static getUserType(state: AuthorizationStateModel) {
        return state.userType;
    }

    @Selector()
    static getRole(state: AuthorizationStateModel) {
        return state.role;
    }

    @Selector()
    static getUserDetails(state: AuthorizationStateModel) {
        return state.details;
    }

    @Action(UserLogin)
    userLogin(ctx: StateContext<AuthorizationStateModel>, action: UserLogin) {
        const isAdmin = action.loginForm.admin;
        delete action.loginForm.admin;
        return this.authorizationService.login(action.loginForm, isAdmin).subscribe({
            next: (resp_json: AuthorisationResponsePayload) => {
                this.tokenService.setAccessToken(resp_json);
                ctx.dispatch(new UserSuccessLogin(resp_json, isAdmin));
                ctx.dispatch(new GetUserDetails());
            },
            error: (error: AuthorisationErrorResponse) => {
                if (typeof error.error.detail === "string") {
                    ctx.dispatch(new UserFailLogin(getErrorMessage(error)));
                } else if (error.error.detail.user_not_confirmed) {
                    ctx.dispatch(new UserNotConfirmed("User email is not confirmed"));
                }
            }
        });
    }

    @Action(UserSuccessLogin)
    userSuccessLogin(ctx: StateContext<AuthorizationStateModel>, action: UserSuccessLogin) {
        ctx.patchState({
            isLoggedIn: true,
            userName: action.payload.user_name,
            email: action.payload.email,
            firstName: action.payload.first_name,
            displayedName: action.payload.displayed_name,
            role: action.payload.role,
            broker_application_finished: action.payload.broker_application_finished,
            is_ncf: action.payload.is_ncf,
            is_nbl: action.payload.is_nbl,
        });

        this.authorizationService.setUpRefreshing(true);

        let url;
        const next = this.route.snapshot.queryParamMap.get('next');
        if (!action.isAdmin && !action.payload.broker_application_finished) {
            url = `/register/broker`;
        } else {
            if (!next || next === '/') {
                url = action.isAdmin ? '/admin' : `/loans`;
            } else {
                url = next;
            }
        }

        this.ngZone.run(() => this.router.navigate([url]));
    }

    @Action(UserFailLogin)
    userFailLogin(ctx: StateContext<AuthorizationStateModel>, action: UserFailLogin) {
        ctx.patchState({
            ...defaultAuthorizationState,
            errorMessage: action.payload,
        });
    }

    @Action(UserNotConfirmed)
    userNotConfirmed(ctx: StateContext<AuthorizationStateModel>, action: UserNotConfirmed) {
        ctx.patchState({
            ...defaultAuthorizationState,
            errorMessage: action.payload,
            userNotConfirmed: true,
        });
    }

    @Action(UserLogout)
    userLogout(ctx: StateContext<AuthorizationStateModel>, action: UserLogout) {
        this.store.reset({
            authorization: {
                ...defaultAuthorizationState,
            },
            loanApplication: {
                ...defaultLoansApplicationState,
            },
            loans: {
                ...defaultLoansState,
            },
            loanApplications: {
                ...defaultAdminLAState,
            },
            statistics: {
                ...defaultStatisticsState,
            },
            brokerRegistration: {
                ...defaultBrokerApplicationState
            }
        });

        this.refreshService.unregisterAllCallbacks();
        this.tokenService.logout();

        if (action.redirect) {
            this.authorizationService.redirectToLogin();
        }
    }

    @Action(UserLogoutOnOtherTab)
    userLogoutOnOtherTab(ctx: StateContext<AuthorizationStateModel>, action: UserLogoutOnOtherTab) {
        ctx.patchState({
            ...defaultAuthorizationState,
        });

        this.refreshService.unregisterAllCallbacks();
        this.authorizationService.redirectToLogin();
    }

    /** Eligibility needs to be passed on to localStorage for old frontend */
    @Action(GetEligibilityCheckData)
    getEligibilityData(ctx: StateContext<AuthorizationStateModel>, action: GetEligibilityCheckData) {
        ctx.patchState({
            eligibility: action.payload
        });
    }

    @Action(GetUserDetails)
    getUserDetails(ctx: StateContext<AuthorizationStateModel>) {
        return this.authorizationService.getUserData().subscribe({
            next: resp_json => {
                ctx.dispatch(new GetUserDetailsSuccess(resp_json));
            },
            error: error => {
                ctx.dispatch(new GetUserDetailsError());
            }
        });
    }

    @Action(GetUserDetailsSuccess)
    getUserDetailsSuccess(ctx: StateContext<AuthorizationStateModel>, action: GetUserDetailsSuccess) {
        ctx.patchState({
            details: action.payload
        });
    }
}

export const defaultAdminLAState = {
    loans: {},
    errorMessage: null,
    riskRegisterError: {},
    drawingRequestError: {},
    selectedLoan: null,
    currentActiveTab: null
};

export const defaultStatisticsState = {
    data: {
        loans_by_statuses: null,
        loans_in_arrears: null,
    },
    reportData: {
        brokers: null,
        customers: null,
        companies: null,
        loans: null,
        approved_loans: null,
        rejected_loans: null
    },
    request: {
        errorMessage: null,
        loading: false
    },
    reportRequest: {
        errorMessage: null,
        loading: false
    },
    csReport: {
        report: {
            url: null,
            name: null,
        },
        error: null,
        isLoading: false,
    },
    eligibilityCheckHistory: {
        records: [],
        error: null,
        loading: false,
    },
    targets: {
        data: {},
        error: null,
    },
};
