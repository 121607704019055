import moment from 'moment';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { LoanApplicationService } from '@nw-app/modules/loan-application/loan-application.service';
import {
    AddFiles, ClearFiles,
    ClearForms,
    CreateLoanApplication,
    CreateLoanApplicationSuccess,
    FinishApplication,
    GetLoanApplication,
    GetLoanApplicationAttachments,
    LoanApplicationError,
    SaveBusinessPlan,
    SaveBusinessPlanSuccess,
    SaveCurrentBorrowing,
    SaveCurrentBorrowingSuccess,
    SaveDebtor,
    SaveDebtorSuccess,
    SaveDocumentsStep,
    SaveDocumentsSuccess,
    SaveLoanCompanyDetails,
    SaveLoanCompanyDetailsSuccess,
    SaveShareholdersDetails,
    SaveShareholdersDetailsSuccess,
    SetApplicationType,
    SetCurrentApplicationId,
    SetFromEligibilityChecker,
    SetSpinner,
    ShareholderSearch,
    UpdateLoanApplication
} from '@nw-app/modules/loan-application/loan-application.actions';
import {
    Attachment,
    BusinessPlanFormData,
    CompanyDetailsFormData,
    CurrentBorrowingFormData,
    DocumentsStepFormData,
    LoanApplicationErrorEntity,
    LoanApplicationDetailsFormData,
    NewLoanApplicationEntity,
    ShareholderSearchData,
    ShareholdersFormData, DebtorFormData,
} from '@nw-app/modules/loan-application/loan-application.interfaces';
import { Dictionary } from '@nw-common/common.interfaces';
import { FileItem } from 'ng2-file-upload';
import { EligibilityState } from '@nw-loans/components/eligibility/eligibility.state';
import { CompanyFinderService } from '@nw-common/services/company-finder.service';
import { take } from 'rxjs/operators';
import { Injectable } from '@angular/core';

export const defaultFormsState = {
    detailsForm: {
        model: null,
        dirty: false,
        status: '',
        errors: null
    },
    companyForm: {
        model: null,
        dirty: false,
        status: '',
        errors: null
    },
    shareholdersForm: {
        model: null,
        dirty: false,
        status: '',
        errors: null
    },
    currentBorrowingForm: {
        model: null,
        dirty: false,
        status: '',
        errors: null
    },
    businessPlanForm: {
        model: null,
        dirty: false,
        status: '',
        errors: null
    },
    documentsForm: {
        bank_statements: [],
        statutory_accounts: [],
        void_cheque: [],
        current_vat_position: [],
        supporting_documents: [],
        personal_al_statements: [],
        model: null,
        dirty: false,
        status: '',
        errors: null
    },
    debtorForm: {
        model: null,
        dirty: false,
        status: '',
        errors: null
    }
};

export const defaultLoansApplicationState = {
    loanApplication: {
        period: null,
        period_days: null,
        amount: null,
        facility_limit: null,
        is_fully_editable: true,
        is_partially_editable: false,
    },
    attachments: {},
    generatedDocuments: [],
    currentId: null,
    currentStep: null,
    fromEligibilityChecker: false,
    directorsSearch: null,
    errors: {},
    guardRedirectLock: false,
    forms: defaultFormsState,
    loadSpinner: null
};

export interface LoanApplicationStateModel {
    loanApplication: NewLoanApplicationEntity;
    attachments: { [key: string]: Attachment[] };
    generatedDocuments: Attachment[];
    currentId: number | string;
    currentStep: string;
    fromEligibilityChecker: boolean;
    directorsSearch: Dictionary<ShareholderSearchData[]>;
    errors: { [key: string]: LoanApplicationErrorEntity };
    guardRedirectLock: boolean;
    forms: {
        detailsForm: {
            model: LoanApplicationDetailsFormData,
            dirty: boolean,
            status: string,
            errors: {}
        },
        companyForm: {
            model: CompanyDetailsFormData,
            dirty: boolean,
            status: string,
            errors: {}
        },
        shareholdersForm: {
            model: ShareholdersFormData,
            dirty: boolean,
            status: string,
            errors: {}
        },
        businessPlanForm: {
            model: BusinessPlanFormData,
            dirty: boolean,
            status: string,
            errors: {}
        },
        currentBorrowingForm: {
            model: CurrentBorrowingFormData,
            dirty: boolean,
            status: string,
            errors: {}
        },
        documentsForm: {
            bank_statements: FileItem[],
            statutory_accounts: FileItem[],
            void_cheque: FileItem[],
            current_vat_position: FileItem[],
            supporting_documents: FileItem[],
            personal_al_statements: FileItem[],
            model: DocumentsStepFormData,
            dirty: boolean,
            status: string,
            errors: {}
        },
        debtorForm: {
            model: DebtorFormData,
            dirty: boolean,
            status: string,
            errors: {}
        },
    };
    loadSpinner: boolean;
}

@State<LoanApplicationStateModel>({
    name: 'loanApplication',
    defaults: { ...defaultLoansApplicationState }
})
@Injectable()
export class LoanApplicationState {
    constructor(private loanApplicationService: LoanApplicationService, private store: Store,
                private companyFinderService: CompanyFinderService) {
    }

    @Selector()
    static loanApplication(state: LoanApplicationStateModel) {
        return state.loanApplication;
    }

    @Selector()
    static getAttachments(state: LoanApplicationStateModel) {
        return state.attachments;
    }

    @Selector()
    static getGeneratedDocuments(state: LoanApplicationStateModel) {
        return state.generatedDocuments;
    }

    @Selector()
    static currentId(state: LoanApplicationStateModel) {
        return state.currentId;
    }

    @Selector()
    static currentStep(state: LoanApplicationStateModel) {
        return state.currentStep;
    }

    @Selector()
    static guardRedirectLock(state: LoanApplicationStateModel) {
        return state.guardRedirectLock;
    }

    @Selector()
    static fromEligibilityChecker(state: LoanApplicationStateModel) {
        return state.fromEligibilityChecker;
    }

    @Selector()
    static getError(step: string) {
        return (state: { loanApplication: LoanApplicationStateModel }) => {
            return state.loanApplication.errors[step];
        };
    }

    @Selector()
    static directors(state: LoanApplicationStateModel) {
        return state.directorsSearch;
    }

    @Selector()
    static companyForm(state: LoanApplicationStateModel) {
        return state.forms.companyForm.model;
    }

    @Selector()
    static shareholdersForm(state: LoanApplicationStateModel) {
        return state.forms.shareholdersForm.model;
    }

    @Selector()
    static currentBorrowingForm(state: LoanApplicationStateModel) {
        return state.forms.currentBorrowingForm.model;
    }

    @Selector()
    static debtorForm(state: LoanApplicationStateModel) {
        return state.forms.debtorForm.model;
    }

    @Selector()
    static detailsForm(state: LoanApplicationStateModel) {
        return state.forms.detailsForm.model;
    }

    @Selector()
    static furtherDetailsForm(state: LoanApplicationStateModel) {
        return state.forms.businessPlanForm.model;
    }

    @Selector()
    static documentsForm(state: LoanApplicationStateModel) {
        return state.forms.documentsForm.model;
    }

    @Selector()
    static getDocuments(groupName: string) {
        return (state: { loanApplication: LoanApplicationStateModel }) => {
            return state.loanApplication.forms.documentsForm[groupName];
        };
    }

    @Selector()
    static referralCode(state: LoanApplicationStateModel) {
        return state.forms.documentsForm.model.referral_code;
    }

    @Selector()
    static isFST(state: LoanApplicationStateModel) {
        return state.loanApplication.is_fst;
    }

    @Selector()
    static loadSpinner(state: LoanApplicationStateModel) {
        return state.loadSpinner;
    }

    @Selector()
    static productType(state: LoanApplicationStateModel) {
        return state.loanApplication.product_type;
    }

    @Selector()
    static isRLS(state: LoanApplicationStateModel) {
        return state.loanApplication.is_rls;
    }

    @Selector()
    static isCommercial(state: LoanApplicationStateModel) {
        return !state.loanApplication.is_individual;
    }

    @Action(SetCurrentApplicationId)
    setCurrentApplicationId(ctx: StateContext<LoanApplicationStateModel>, action: SetCurrentApplicationId) {
        ctx.patchState({
            guardRedirectLock: false,
        });
        if (!['UKEF', 'EWCS', 'REVOLV', 'SUPP', 'SINVFIN', 'BBB_GL', 'BBB_RND', 'BBB_PERF'].includes(action.id as string)) {
            ctx.dispatch([new ClearForms(), new GetLoanApplication(Number(action.id))]);
        } else {
            if (['BBB'].includes(action.id as string)) {
                ctx.dispatch(new SetFromEligibilityChecker(action.fromEligibilityChecker));
            }

            ctx.patchState({
                currentId: action.id,
                currentStep: 'loanDetails',
                loadSpinner: false,
                loanApplication: {
                    ...defaultLoansApplicationState.loanApplication,
                    product_type: action.id as string,
                },
                forms: {
                    detailsForm: {...defaultFormsState.detailsForm, model: null},
                    companyForm: {...defaultFormsState.companyForm, model: null},
                    currentBorrowingForm: {...defaultFormsState.currentBorrowingForm, model: null},
                    shareholdersForm: {...defaultFormsState.shareholdersForm, model: null},
                    businessPlanForm: {...defaultFormsState.businessPlanForm, model: null},
                    documentsForm: {...defaultFormsState.documentsForm, model: null},
                    debtorForm: {...defaultFormsState.debtorForm, model: null},
                }
            });
        }
    }

    @Action(SetFromEligibilityChecker)
    setFromEligibilityChecker(ctx: StateContext<LoanApplicationStateModel>, action: SetFromEligibilityChecker) {
        const state: LoanApplicationStateModel = ctx.getState();
        const forms = { ...state.forms };

        for (const form of Object.keys(forms)) {
            delete forms[form].model;
        }

        if (action.value) {
            this.store.select(EligibilityState.eligibilityData).pipe(take(1)).subscribe(data => {
                this.companyFinderService.get(data.company.registration_number).subscribe(result => {
                    forms.detailsForm.model = {
                        period: data.request_data.period,
                        amount: data.request_data.amount,
                    };
                    forms.companyForm.model = {
                        ...forms.companyForm.model,
                        company_legal_name: result.company_legal_name,
                        company_registration_number: result.company_registration_number,
                        legal_address: {
                            ...result.legal_address,
                            is_property_owner: null,
                        },
                        business_start_date: moment(result.business_start_date, 'DD/MM/YYYY').format('YYYY-MM-DD'),
                        company_type: result.type
                    };
                    ctx.patchState({
                        fromEligibilityChecker: action.value,
                        forms: forms
                    });
                });
            });
        } else {
            forms.detailsForm.model = {
                period: 12,
                amount: 26000,
                // facility_limit: 25000,
            };
            ctx.patchState({
                fromEligibilityChecker: action.value,
                forms: forms
            });
        }
    }

    @Action(GetLoanApplication)
    getLoanApplication(ctx: StateContext<LoanApplicationStateModel>, action: GetLoanApplication) {
        this.loanApplicationService.getLoan(action.id).subscribe({
            next: response => {
                ctx.dispatch(new SetSpinner(false));
                ctx.dispatch(new UpdateLoanApplication(response, action.form));
            },
            error: error => ctx.dispatch(new LoanApplicationError(error, 'general'))
        });
    }

    @Action(GetLoanApplicationAttachments)
    getLoanApplicationAttachments(ctx: StateContext<LoanApplicationStateModel>, action: GetLoanApplicationAttachments) {
        const state: LoanApplicationStateModel = ctx.getState();
        this.loanApplicationService.getAttachments(state.currentId as number).subscribe(
            response => {
                ctx.patchState({
                    attachments: response.uploaded,
                    generatedDocuments: response.regenerated.length > 0 ? response.regenerated : response.generated,
                });
            },
            error => ctx.dispatch(new LoanApplicationError(error, 'general'))
        );
    }

    _prepareValueForMultiSelect(value, options) {
        const result = [];
        for (const index in options) {
            if (value.indexOf(options[index]) > -1) {
                result.push({
                    id: Number(index),
                    itemName: options[index],
                    value: options[index],
                });
            }
        }
        return result;
    }

    @Action(UpdateLoanApplication)
    updateLoanApplication(ctx: StateContext<LoanApplicationStateModel>, action: UpdateLoanApplication) {
        const state: LoanApplicationStateModel = ctx.getState();
        ctx.patchState({
            loanApplication: action.payload,
            currentId: action.payload.id,
            currentStep: action.payload.step,
            errors: {},
        });

        const forms = { ...state.forms };

        let formsIndex: any;

        formsIndex = {
            detailsForm: Object.assign(
                // action.payload.ncf_details,
                {
                    amount: action.payload.amount,
                    period: action.payload.period,
                    period_days: action.payload.period_days,
                    n5__of_your_turnover_is_export_sales: action.payload.n5__of_your_turnover_is_export_sales,
                    // facility_limit: action.payload.facility_limit,
                    location_confirmation: action.payload.location_confirmation,
                    location_northern_ireland: action.payload.location_northern_ireland,
                    location_scotland: action.payload.location_scotland,
                    location_subsidiaries: action.payload.location_subsidiaries,
                    location_wholesale_electricity: action.payload.location_wholesale_electricity,
                    // rls_type: action.payload.rls_type,
                    // repayment_profile: action.payload.product_type === 'RBP' ? 'retained' : 'serviced',
                })
        };



        if (action.payload.company) {
            formsIndex = {
                detailsForm: Object.assign(
                    // action.payload.ncf_details,
                    {
                        amount: action.payload.amount,
                        period: action.payload.period,
                        period_days: action.payload.period_days,
                        n5__of_your_turnover_is_export_sales: action.payload.n5__of_your_turnover_is_export_sales,
                        // facility_limit: action.payload.facility_limit,
                        location_confirmation: action.payload.location_confirmation,
                        location_northern_ireland: action.payload.location_northern_ireland,
                        location_scotland: action.payload.location_scotland,
                        location_subsidiaries: action.payload.location_subsidiaries,
                        location_wholesale_electricity: action.payload.location_wholesale_electricity,
                        // rls_type: action.payload.rls_type,
                        // annual_turnover: action.payload.annual_turnover
                    }),
                companyForm: Object.assign(
                    action.payload.company,
                    {
                        vat_registered: action.payload.vat_registered,
                    //     vat_number: action.payload.vat_number,
                        vat_up_to_date: action.payload.vat_up_to_date,
                        vat_up_to_date_details: action.payload.vat_up_to_date_details,
                        no_of_employees: action.payload.no_of_employees,
                        financial_year_end: action.payload.financial_year_end,
                        business_nature: action.payload.business_nature,
                    //     business_credit_sales: action.payload.business_credit_sales,
                    //     terms_of_sale: action.payload.terms_of_sale,
                        insolvency_procedures: action.payload.insolvency_procedures,
                    //     insolvency_details: action.payload.insolvency_details,
                    //     state_aid: action.payload.state_aid,
                    //     vat_exempt: action.payload.vat_exempt,
                    //     annual_turnover: action.payload.annual_turnover,
                    //     sector: action.payload.sector,
                        codat_question: action.payload.codat_question,
                        accounting_software: action.payload.accounting_software,
                    //     accounting_software_other: action.payload.accounting_software_other
                    }
                    ),
                shareholdersForm: {
                    directors: action.payload.company.shareholders,
                    consent_of_stakeholders_apply: action.payload.consent_of_stakeholders_apply,
                    consent_of_stakeholders_credit_check: action.payload.consent_of_stakeholders_credit_check
                },
                businessPlanForm: {
                    // areas_for_mentoring: this._prepareValueForMultiSelect(
                    //     action.payload.further_information.areas_for_mentoring ||
                    //     [], shared['lists'].arrears_for_mentoring
                    // ),
                    funding_purpose:
                    action.payload.further_information.funding_purpose,
                    // turnover_increase:
                    // action.payload.further_information.turnover_increase,
                    covid_impact:
                    action.payload.further_information.covid_impact,
                    // purposes:
                    //     this._prepareValueForMultiSelect(
                    //         action.payload.purposes || [], shared['lists'].purposes
                    //     ),
                    // prop_offered: action.payload.further_information.prop_offered,
                    climate_change_risk: action.payload.further_information.climate_change_risk,
                    change_to_climate_change_impacts: action.payload.further_information.change_to_climate_change_impacts,
                    // property_address_rls: action.payload.further_information.property_address_rls,
                    main_products_services: action.payload.further_information.main_products_services,
                    customer_sectors: action.payload.further_information.customer_sectors,
                    uk_manufactured: action.payload.further_information.uk_manufactured,
                    uk_manufactured_percentage: action.payload.further_information.uk_manufactured_percentage,
                    solid_wood_packaging: action.payload.further_information.solid_wood_packaging,
                    ispm15: action.payload.further_information.ispm15,
                    export_countries: action.payload.further_information.export_countries,
                    export_customers_number: action.payload.further_information.export_customers_number,
                    terms_of_trade: action.payload.further_information.terms_of_trade,
                    annual_export_revenue: action.payload.further_information.annual_export_revenue,
                    // number_of_properties: action.payload.further_information.number_of_properties,
                    // properties: action.payload.further_information.properties,

                },
                currentBorrowingForm: {
                    cbils_approved: action.payload.cbils_approved,
                    other_borrowings: action.payload.other_borrowings,
                    facilities: action.payload.facilities
                },
                documentsForm: {
                    // referral_code: action.payload.referral_code,
                    // areas_for_mentoring: this._prepareValueForMultiSelect(
                    //     action.payload.further_information.areas_for_mentoring ||
                    //     [], shared['lists'].arrears_for_mentoring
                    // ),
                    // investment_purpose: action.payload.further_information.investment_purpose,
                    // additional_comments: action.payload.additional_comments,
                    // business_bank_account_number: action.payload.company.business_bank_account_number,
                    // business_bank_account_sort_code: action.payload.company.business_bank_account_sort_code,
                    // business_bank_name: action.payload.company.business_bank_name,
                    // nbl_internal_referrer: action.payload.nbl_internal_referrer
                },
                debtorForm: {
                    details_of_suppliers: action.payload.details_of_suppliers,
                    details_of_debtors: action.payload.details_of_debtors,
                }
            };
        }
        if (action.form) {
            forms[action.form].model = formsIndex[action.form];

        } else if (!action.form && !action.payload.company && !state.fromEligibilityChecker) {
            for (const form of Object.keys(forms)) {
                delete forms[form].model;
            }
            forms.detailsForm.model = formsIndex.detailsForm;

        } else {
            forms.detailsForm.model = formsIndex.detailsForm;
            forms.companyForm.model = formsIndex.companyForm;
            forms.currentBorrowingForm.model = formsIndex.currentBorrowingForm;
            forms.shareholdersForm.model = formsIndex.shareholdersForm;
            forms.businessPlanForm.model = formsIndex.businessPlanForm;
            forms.documentsForm.model = formsIndex.documentsForm;
            forms.debtorForm.model = formsIndex.debtorForm;
        }

        ctx.patchState({
            forms: forms,
        });
    }

    @Action(LoanApplicationError)
    loanApplicationError(ctx: StateContext<LoanApplicationStateModel>, action: LoanApplicationError) {
        const state = ctx.getState();
        const errors = { ...state.errors };
        const error = action.error;

        errors[action.step] = {
            message: error.error.error_messages ? error.error.error_messages : error.message,
            code: error.status,
        };

        ctx.patchState({
            errors: errors,
            loadSpinner: false
        });
    }

    @Action(CreateLoanApplication)
    createLoanApplication(ctx: StateContext<LoanApplicationStateModel>, action: CreateLoanApplication) {
        const state = ctx.getState();

        ctx.patchState({
            guardRedirectLock: true,
        });

        this.loanApplicationService.saveLoanDetails(state.currentId, action.payload).subscribe(
            response => ctx.dispatch(new CreateLoanApplicationSuccess(response)),
            error => ctx.dispatch(new LoanApplicationError(error, 'loanDetails'))
        );
    }

    @Action(CreateLoanApplicationSuccess)
    createLoanApplicationSuccess(ctx: StateContext<LoanApplicationStateModel>, action: CreateLoanApplicationSuccess) {
        ctx.dispatch(new UpdateLoanApplication(action.loan, 'detailsForm'));
    }

    @Action(SaveLoanCompanyDetails)
    saveLoanCompanyDetails(ctx: StateContext<LoanApplicationStateModel>, action: SaveLoanCompanyDetails) {
        const state: LoanApplicationStateModel = ctx.getState();

        ctx.patchState({
            guardRedirectLock: true,
        });

        this.loanApplicationService.saveLoanCompanyDetails(state.currentId as number, action.payload).subscribe(
            response => ctx.dispatch(new SaveLoanCompanyDetailsSuccess(response)),
            error => ctx.dispatch(new LoanApplicationError(error, 'businessDetails'))
        );
    }

    @Action(SaveLoanCompanyDetailsSuccess)
    saveLoanCompanyDetailsSuccess(ctx: StateContext<LoanApplicationStateModel>, action: SaveLoanCompanyDetailsSuccess) {
        ctx.dispatch(new UpdateLoanApplication(action.loan, 'companyForm'));
    }

    @Action(SaveShareholdersDetails)
    saveShareholdersDetails(ctx: StateContext<LoanApplicationStateModel>, action: SaveShareholdersDetails) {
        const state: LoanApplicationStateModel = ctx.getState();

        ctx.patchState({
            guardRedirectLock: true,
        });

        this.loanApplicationService.saveShareholdersDetails(state.currentId as number, action.payload).subscribe(
            response => ctx.dispatch(new SaveShareholdersDetailsSuccess(response)),
            error => ctx.dispatch(new LoanApplicationError(error, 'shareholdersDetails'))
        );
    }

    @Action(SaveShareholdersDetailsSuccess)
    saveShareholdersDetailsSuccess(ctx: StateContext<LoanApplicationStateModel>, action: SaveShareholdersDetailsSuccess) {
        ctx.dispatch(new UpdateLoanApplication(action.loan, 'shareholdersForm'));
    }

    @Action(SaveBusinessPlan)
    saveBusinessPlan(ctx: StateContext<LoanApplicationStateModel>, action: SaveBusinessPlan) {
        const state: LoanApplicationStateModel = ctx.getState();

        ctx.patchState({
            guardRedirectLock: true,
        });

        this.loanApplicationService.saveBusinessPlan(state.currentId as number, action.payload).subscribe(
            response => ctx.dispatch(new SaveBusinessPlanSuccess(response)),
            error => ctx.dispatch(new LoanApplicationError(error, 'businessPlan'))
        );
    }

    @Action(SaveBusinessPlanSuccess)
    saveBusinessPlanSuccess(ctx: StateContext<LoanApplicationStateModel>, action: SaveBusinessPlanSuccess) {
        ctx.dispatch(new UpdateLoanApplication(action.loan, 'businessPlanForm'));
    }

    @Action(SaveCurrentBorrowing)
    saveCurrentBorrowing(ctx: StateContext<LoanApplicationStateModel>, action: SaveCurrentBorrowing) {
        const state: LoanApplicationStateModel = ctx.getState();

        ctx.patchState({
            guardRedirectLock: true,
        });

        this.loanApplicationService.saveCurrentBorrowing(state.currentId as number, action.payload).subscribe(
            response => ctx.dispatch(new SaveCurrentBorrowingSuccess(response)),
            error => ctx.dispatch(new LoanApplicationError(error, 'currentBorrowing'))
        );
    }

    @Action(SaveCurrentBorrowingSuccess)
    saveCurrentBorrowingSuccess(ctx: StateContext<LoanApplicationStateModel>, action: SaveCurrentBorrowingSuccess) {
        ctx.dispatch(new UpdateLoanApplication(action.loan, 'currentBorrowingForm'));
    }

    @Action(SaveDebtor)
    saveDebtor(ctx: StateContext<LoanApplicationStateModel>, action: SaveDebtor) {
        const state: LoanApplicationStateModel = ctx.getState();

        ctx.patchState({
            guardRedirectLock: true,
        });

        const service = action.isSupplier ? this.loanApplicationService.saveSuppliers.bind(this.loanApplicationService) : this.loanApplicationService.saveDebtor.bind(this.loanApplicationService)

        service(state.currentId as number, action.payload).subscribe({
            next: response => ctx.dispatch(new SaveDebtorSuccess(response)),
            error: error => ctx.dispatch(new LoanApplicationError(error, 'currentBorrowing'))
        });
    }

    @Action(SaveDebtorSuccess)
    saveDebtorSuccess(ctx: StateContext<LoanApplicationStateModel>, action: SaveDebtorSuccess) {
        ctx.dispatch(new UpdateLoanApplication(action.loan, 'debtorForm'));
    }

    @Action(ShareholderSearch)
    shareholdersSearch(ctx: StateContext<LoanApplicationStateModel>, action: ShareholderSearch) {
        const state: LoanApplicationStateModel = ctx.getState();
        ctx.patchState({
            directorsSearch: {
                ...state.directorsSearch,
                [action.loanId]: action.payload
            }
        });
    }

    @Action(SaveDocumentsStep)
    saveLoanDocumentsStep(ctx: StateContext<LoanApplicationStateModel>, action: SaveDocumentsStep) {
        const state: LoanApplicationStateModel = ctx.getState();
        this.loanApplicationService.saveLoanDocumentsStep(state.currentId as number, action.payload).subscribe(
            response => {
                if (action.finish) {
                    ctx.dispatch(new FinishApplication());
                } else {
                    ctx.dispatch(new SaveDocumentsSuccess(state.currentId as number));
                }
            },
            error => ctx.dispatch(new LoanApplicationError(error, 'appDocuments'))
        );
    }

    @Action(FinishApplication)
    finishApplication(ctx: StateContext<LoanApplicationStateModel>, action: FinishApplication) {
        const state: LoanApplicationStateModel = ctx.getState();

        ctx.patchState({
            guardRedirectLock: true,
        });

        if (state.currentStep !== 'FINISHED') {
            this.loanApplicationService.markApplicationAsFinished(state.currentId as number).subscribe(
                response => {
                    ctx.patchState({
                        currentStep: 'FINISHED'
                    });
                    ctx.dispatch(new SaveDocumentsSuccess(state.currentId as number, true));
                },
                error => ctx.dispatch(new LoanApplicationError(error, 'appDocuments'))
            );
        } else {
            ctx.dispatch(new SaveDocumentsSuccess(state.currentId as number));
        }
    }

    @Action(SaveDocumentsSuccess)
    saveDocumentsSuccess(ctx: StateContext<LoanApplicationStateModel>, action: SaveDocumentsSuccess) {
        const state: LoanApplicationStateModel = ctx.getState();
        ctx.dispatch(new GetLoanApplication(action.loanId, 'documentsForm'));
        if (!state.loanApplication.is_fst) {
            ctx.dispatch(new GetLoanApplicationAttachments());
        }
    }

    @Action(AddFiles)
    addFilesToStore(ctx: StateContext<LoanApplicationStateModel>, action: AddFiles) {
        const state: LoanApplicationStateModel = ctx.getState();
        const documents = { ...state.forms.documentsForm };

        documents[action.groupName] = action.files;

        ctx.patchState({
            forms: {
                ...state.forms,
                documentsForm: documents,
            }
        });
    }


    @Action(ClearFiles)
    clearFiles(ctx: StateContext<LoanApplicationStateModel>) {
        const state: LoanApplicationStateModel = ctx.getState();

        ctx.patchState({
            forms: {
                ...state.forms,
                documentsForm: {
                    ...state.forms.documentsForm,
                    bank_statements: [],
                    statutory_accounts: [],
                    void_cheque: [],
                    current_vat_position: [],
                    supporting_documents: [],
                    personal_al_statements: []
                }
            }
        });
    }

    @Action(ClearForms)
    clearForms(ctx: StateContext<LoanApplicationStateModel>, action: ClearForms) {
        ctx.patchState({
            loanApplication: {...defaultLoansApplicationState.loanApplication},
            forms: {
                detailsForm: {...defaultFormsState.detailsForm, model: null},
                companyForm: {...defaultFormsState.companyForm, model: null},
                currentBorrowingForm: {...defaultFormsState.currentBorrowingForm, model: null},
                shareholdersForm: {...defaultFormsState.shareholdersForm, model: null},
                businessPlanForm: {...defaultFormsState.businessPlanForm, model: null},
                documentsForm: {...defaultFormsState.documentsForm, model: null},
                debtorForm: {...defaultFormsState.debtorForm, model: null},
            }
        });
    }

    @Action(SetSpinner)
    setSpinner(ctx: StateContext<LoanApplicationStateModel>, action: SetSpinner) {
        ctx.patchState({
            loadSpinner: action.spinner
        });
    }

    @Action(SetApplicationType)
    setIndividual(ctx: StateContext<LoanApplicationStateModel>, action: SetApplicationType) {
        const state: LoanApplicationStateModel = ctx.getState();
        ctx.patchState({
            loanApplication: {
                ...state.loanApplication,
                ncf_details: {
                    ...state.loanApplication.ncf_details,
                    application_type: action.payload
                },
                is_individual: action.payload === 'individual'
            }
        });
    }
}
