import { Component, OnInit } from '@angular/core';
import { CookiesBanner } from '../../actions/commons.actions';
import { Observable } from 'rxjs';
import shared from '@nw-root/../../shared.json';
import { Select, Store } from '@ngxs/store';
import { CommonsState } from '@nw-common/commons.state';

@Component({
    selector: 'nw-cookie-law-banner',
    templateUrl: './cookie-law-banner.component.html',
    styleUrls: ['./cookie-law-banner.component.scss']
})
export class CookieLawBannerComponent implements OnInit {
    @Select(CommonsState.cookieBannerVisible) bannerVisible: Observable<boolean>;
    private readonly localStorageKey = 'cookieLawConsent';
    shared: any;

    constructor(private store: Store) {
        this.shared = shared;
    }

    ngOnInit() {
        if (!this.getFromLS()) {
            this.store.dispatch(new CookiesBanner(true));
        }
    }

    close() {
        this.setLS();
        this.store.dispatch(new CookiesBanner(false));
    }

    getFromLS() {
        return localStorage.getItem(this.localStorageKey);
    }

    setLS() {
        return localStorage.setItem(this.localStorageKey, '1');
    }
}
