import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlSegment } from '@angular/router';
import { objectToQueryString } from '@nw-app/utils/routing';

@Injectable()
export class RoutingGuard implements CanActivate {
    private oldRoutes = ['gocardless_generate', 'gocardless_confirmation'];

    constructor(private router: Router) {
    }

    private concatUrl(url: UrlSegment[], queryParams: object) {
        return url.map(x => x.path).join('/') + objectToQueryString(queryParams, false);
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        if (route.url.length > 1) {
            const concatPath = route.url[0].path + '/' + route.url[1].path;

            if (this.oldRoutes.indexOf(concatPath) > -1) {
                window.location.href = `/${ this.concatUrl(route.url, route.queryParams) }`;
                return false;
            }
        }

        if (route.url[0] && this.oldRoutes.indexOf(route.url[0].path) > -1) {
            window.location.href = `/${ this.concatUrl(route.url, route.queryParams) }`;
            return false;
        }

        if (route.url.length === 1 && route.url[0].path === 'register') {
            window.location.href = `/${ this.concatUrl(route.url, route.queryParams) }`;
            return false;
        }

        return true;
    }
}
