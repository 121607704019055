import { Observable, throwError } from 'rxjs';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';


import { CustomPopupService } from '@nw-common/services/popup.service';

import { environment } from '@nw-root/environments/environment';
import { makeid } from '@nw-app/utils/uuid';
import { catchError } from 'rxjs/operators';

@Injectable()
export class SessionTokenInterceptor implements HttpInterceptor {
    private readonly cookieName = 'session';

    constructor(private cookieService: CookieService) {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        if (!this.cookieService.check(this.cookieName)) {
            this.cookieService.set(this.cookieName, makeid(), null, '/', null, true);
        }

        return next.handle(request);
    }
}


@Injectable()
export class AppVersionInterceptor implements HttpInterceptor {
    private readonly headerName = 'App-Id';

    constructor() {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (this.isApiRequest(request.url)) {
            return next.handle(
                request.clone({
                  headers: request.headers.set(this.headerName, environment.appVersion)
                })
            );
        }

        return next.handle(request);
    }

    isApiRequest(url: string) {
        return url.includes('/api/v1/');
    }
}


@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
    constructor(private popupService: CustomPopupService) {
    }

    shouldShowRefreshModal(error: any) {
        const statusString = error.status.toString();
        return (
            statusString.startsWith('4') || statusString.startsWith('5')
        ) && error.status !== 429 && error.headers.get('force-refresh') === '1';
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(catchError((error: any) => {
            if (this.shouldShowRefreshModal(error)) {
                this.popupService.showAppRefreshError();
            } else if (error instanceof HttpErrorResponse) {
                if (
                    !error.status ||
                    error.status === 500 ||
                    error.status === 502 ||
                    error.status === -1
                ) {
                    this.popupService.showUnexpectedError();
                }
            }
            return throwError(() => error);
        }));
    }
}
