import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { EnabledsService } from '../services/enableds.service';

@Directive({
    selector: '[nwEnabled]'
})
export class EnabledDirective {
    private hasView = false;

    constructor(private templateRef: TemplateRef<any>,
                private viewContainer: ViewContainerRef,
                private enableds: EnabledsService) { }

    @Input() set nwEnabled(name: string) {
        const condition: boolean = this.enableds.getEnabled(name);

        if (condition && !this.hasView) {
            this.viewContainer.createEmbeddedView(this.templateRef);
            this.hasView = true;
        } else if (!condition && this.hasView) {
            this.viewContainer.clear();
            this.hasView = false;
        }
    }

}
