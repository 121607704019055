import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

import { EnabledsService } from './enableds.service';

declare var gtag;

@Injectable()
export class ScriptsService {
    private allowedAWpaths = [
        'http://lending.newable.com/registration_success',
        'http://lending.newable.com/loan_application/NEW'
    ];


    constructor(private es: EnabledsService, private router: Router) {
    }

    insertScripts() {
        this.insertHotjar();
        this.insertDevTest();
        this.insertGA();
        this.insertPardot();
    }

    insertHotjar() {
        this.insertScript(
            'HOTJAR',
            `(function(h,o,t,j,a,r){
                h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
                h._hjSettings={hjid:736360,hjsv:6};
                a=o.getElementsByTagName('head')[0];
                r=o.createElement('script');r.async=1;
                r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
                a.appendChild(r);
            })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');`
        );
    }

    insertGA() {
        if (this.es.getEnabled('GA')) {
            let script = document.createElement('script');
            script.async = true;
            script.src = 'https://www.googletagmanager.com/gtag/js';
            document.head.appendChild(script);

            script = document.createElement('script');
            script.innerHTML = `
                window.dataLayer = window.dataLayer || [];
                function gtag(){dataLayer.push(arguments);}
                gtag('js', new Date());
            `;
            document.head.appendChild(script);

            this.router.events.subscribe(event => {
                if (event instanceof NavigationEnd) {
                    gtag('config', 'UA-85536541-5', { 'page_path': event.urlAfterRedirects });
                    gtag('config', 'AW-832008379', { 'page_path': event.urlAfterRedirects });

                    if (this.allowedAWpaths.indexOf(window.location.href) > -1) {
                        gtag('event', 'conversion', { 'send_to': 'AW-832008379/piUXCL2yrX8Qu-HdjAM' });
                    }
                }
            });
        }
    }

    insertPardot() {
            this.insertScript(
                'PARDOT',
                `
piAId = '463372';
piCId = '83242';
piHostname = 'pi.pardot.com';

(function() {
    function async_load(){
        var s = document.createElement('script'); s.type = 'text/javascript';
        s.src = ('https:' == document.location.protocol ? 'https://pi' : 'http://cdn') + '.pardot.com/pd.js';
        var c = document.getElementsByTagName('script')[0]; c.parentNode.insertBefore(s, c);
    }

    if(window.attachEvent) { window.attachEvent('onload', async_load); }
    else { window.addEventListener('load', async_load, false); }
})();
                `);
    }

    insertDevTest() {
        this.insertScript(
            'DEV',
            `console.log('Works');`
        );
    }

    private insertScript(enabledName: string, content: string, comment?: string) {

        const script = document.createElement('script');

        if (this.es.getEnabled(enabledName)) {
            script.innerHTML = content;
        } else {
            script.innerHTML = `<!-- ${enabledName} is disabled -->`;
        }

        document.head.appendChild(script);
    }
}
